import { campaignRequest, campaignRequestV2 } from "./api";
import {
  ExportSummary,
  ExportResourceResp,
  ExportCampaignReport,
  ExportStatusResp,
} from "../../types/export";
const EMAIL_RECIPIENTS_REPORT_SEARCH_FIELDS = [
  "email",
  "name",
  "first_name",
  "last_name",
];

export async function exportDynamicListApi(
  dynamicListId: string
): Promise<ExportResourceResp> {
  return campaignRequestV2({
    url: `/assets/dynamic-lists/${dynamicListId}/export`,
  });
}

export async function exportStaticListApi(
  staticListId: string
): Promise<ExportResourceResp> {
  return campaignRequestV2({
    url: `/assets/static-lists/${staticListId}/export`,
  });
}

export async function exportCampaignReportApi({
  campaignId,
  reportType,
  actionId,
  searchQuery,
  startDate,
  endDate,
}: ExportCampaignReport): Promise<ExportResourceResp> {
  return campaignRequestV2({
    url: `/campaigns/${campaignId}/report/export`,
    data: {
      report_type: reportType,
      action_id: actionId,
      query: {
        search: {
          keyword: searchQuery,
          fields: EMAIL_RECIPIENTS_REPORT_SEARCH_FIELDS,
        },
      },
      start_date: startDate,
      end_date: endDate,
    },
  });
}

export async function getExportStatusApi(
  exportId: string
): Promise<ExportStatusResp> {
  return campaignRequestV2({
    url: `/exports/${exportId}`,
    method: "GET",
  });
}

export async function abortExportApi(exportId: string): Promise<ExportSummary> {
  return campaignRequestV2({
    url: `/exports/${exportId}/abort`,
  });
}

export async function exportJourneyMemberReportApi({
  campaignId,
  startDate,
  endDate,
  query,
  showUniqueEntries,
  columnsToSearchIn,
}: {
  campaignId: string;
  startDate: string;
  endDate: string;
  query: string;
  showUniqueEntries: boolean;
  columnsToSearchIn: string[];
}): Promise<ExportResourceResp> {
  return campaignRequest({
    url: `/campaign-reports/campaign/${campaignId}/journey/export`,
    method: "GET",
    params: {
      start_date: startDate,
      end_date: endDate,
      query: { search: { keyword: query, fields: columnsToSearchIn } },
      show_unique_entries: showUniqueEntries,
    },
  });
}

export async function exportFlowStepReportApi({
  actionId,
  campaignId,
  startDate,
  endDate,
  query,
  columnsToSearchIn,
}: {
  actionId: string;
  campaignId: string;
  startDate: string;
  endDate: string;
  query: string;
  columnsToSearchIn: string[];
}): Promise<ExportResourceResp> {
  return campaignRequest({
    url: `/campaign-reports/campaign/${campaignId}/action/${actionId}/action-detail/export`,
    method: "GET",
    params: {
      start_date: startDate,
      end_date: endDate,
      query: { search: { keyword: query, fields: columnsToSearchIn } },
    },
  });
}

export async function exportFlowStepMemberReportApi({
  actionId,
  campaignId,
  branchId,
  startDate,
  endDate,
  query,
  columnsToSearchIn,
}: {
  actionId: string;
  campaignId: string;
  branchId: string | undefined;
  startDate: string;
  endDate: string;
  query: string;
  columnsToSearchIn: string[];
}): Promise<ExportResourceResp> {
  return campaignRequest({
    url: `/campaign-reports/campaign/${campaignId}/action/${actionId}/members/export`,
    method: "GET",
    params: {
      ui_branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
      query: { search: { keyword: query, fields: columnsToSearchIn } },
    },
  });
}
