import { useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import { useSelector } from "react-redux";
import {
  INCLUSION_LIST_TYPES,
  MEMBERSHIP_ASSET_TYPES,
} from "../common/types/campaign";
import { AssetField } from "../common/types/connection";
import { selectCampaign } from "../pages/dashboard/campaign/campaignSlice";
import {
  listStaticListContacts,
  listStaticListSummaryList,
  selectStaticList,
} from "../pages/dashboard/staticlist/staticlistSlice";
import { useAppDispatch } from "../store";
import {
  convertToString,
  isLoading,
  isSuccess,
} from "../common/helper/commonHelper";
import DropdownWithSearch from "../components/DropdownWithSearch";
import {
  GroupBase,
  OptionProps,
  SingleValue,
  SingleValueProps,
  components,
} from "react-select";
import {
  Badge,
  Box,
  HStack,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import IModal from "./IModal";
import { SearchField } from "./SearchField";
import { debounce } from "lodash";
import { DataTable } from "./data-table/DataTable";
import { staticListContactsPreviewTableHeaderColumnDef } from "../pages/dashboard/staticlist/components/tableDefinitions";
import {
  listAllCampaigns,
  listAllDynamicLists,
  selectDynamicList,
} from "./dynamic-list/dynamicListSlice";
import DynamicListPreview from "./dynamic-list/DynamicListPreview";

export enum LIST_SELECTION_DROPDOWN_VARIANT {
  CAMPAIGN = "campaign",
  DYNAMIC_LIST = "dynamic_list",
}

const LIST_SPECIFIC_BADGE_INFO = {
  [INCLUSION_LIST_TYPES.DYNAMIC]: {
    color: "brand.green",
    display: "Dynamic list",
    badge: "DL",
  },
  [INCLUSION_LIST_TYPES.STATIC]: {
    color: "blue.400",
    display: "Static list",
    badge: "SL",
  },
  [MEMBERSHIP_ASSET_TYPES.DYNAMIC]: {
    color: "brand.green",
    display: "Dynamic list",
    badge: "DL",
  },
  [MEMBERSHIP_ASSET_TYPES.STATIC]: {
    color: "blue.400",
    display: "Static list",
    badge: "SL",
  },
};

type AssetFieldWithPreview = {
  previewAsset?: (data: AssetFieldWithPreview) => void;
} & AssetField;

export const CustomAssetBadgeOption = ({
  children,
  data,
  ...props
}: OptionProps<
  AssetFieldWithPreview,
  false,
  GroupBase<AssetFieldWithPreview>
>) => {
  const [iconHovered, setIconHovered] = useState(false);

  return (
    <HStack
      justifyContent="space-between"
      alignItems="baseline"
      py="2"
      px="3"
      bg={props.isSelected ? "gray.200" : ""}
      _hover={props.isSelected || iconHovered ? {} : { bg: "gray.100" }}
      onClick={() => props.setValue(data, "select-option")}
    >
      <HStack alignItems="baseline">
        <Badge
          fontSize="10px"
          backgroundColor={LIST_SPECIFIC_BADGE_INFO[data.type].color}
          variant="solid"
          textAlign="center"
          width="80px"
        >
          {LIST_SPECIFIC_BADGE_INFO[data.type].display}
        </Badge>
        <Text fontSize="sm" isTruncated maxW="600px">
          {data.name}
        </Text>
      </HStack>
      {data.previewAsset && (
        <IconButton
          icon={<FaEye />}
          aria-label="preview"
          variant="ghost"
          color="brand.blue"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            data.previewAsset?.(data);
          }}
          onMouseEnter={() => setIconHovered(true)}
          onMouseLeave={() => setIconHovered(false)}
        />
      )}
    </HStack>
  );
};

export function AssetNameWithTag({
  isReadOnly = false,
  data,
  quotes,
}: {
  isReadOnly?: boolean;
  data: AssetFieldWithPreview | null;
  quotes?: boolean;
}) {
  const textStyle = isReadOnly
    ? {
        width: "72%",
      }
    : {
        minWidth: "72%",
      };
  return (
    <HStack justifyContent="space-between">
      <Text
        {...textStyle}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {quotes ? `"${data?.name ?? ""}"` : data?.name}
      </Text>
      {data && (
        <Tooltip
          fontSize="10px"
          fontWeight="semibold"
          label={LIST_SPECIFIC_BADGE_INFO[data.type].display}
        >
          <Badge
            fontSize="10px"
            backgroundColor={LIST_SPECIFIC_BADGE_INFO[data.type].color}
            variant="solid"
            flex="0 0 fit-content"
            textAlign="center"
            width="80px"
          >
            {isReadOnly
              ? LIST_SPECIFIC_BADGE_INFO[data.type].badge
              : LIST_SPECIFIC_BADGE_INFO[data.type].display}
          </Badge>
        </Tooltip>
      )}
    </HStack>
  );
}

export function CustomSingleValue({
  children,
  ...props
}: SingleValueProps<
  AssetFieldWithPreview,
  false,
  GroupBase<AssetFieldWithPreview>
>) {
  const values = props.getValue();
  const data = values.length ? values[0] : null;

  return (
    <components.SingleValue {...props}>
      <AssetNameWithTag data={data} />
    </components.SingleValue>
  );
}

export function ListSelectionDropdown({
  variant,
  value,
  onChange,
  isInvalid,
  isReadOnly,
  onPreviewAsset,
  loading,
}: {
  variant: LIST_SELECTION_DROPDOWN_VARIANT;
  value: string | number | null;
  onChange: (id: AssetField | null) => void;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  loading?: boolean;
  onPreviewAsset?: (data: AssetFieldWithPreview) => void;
}) {
  const dispatch = useAppDispatch();

  const { campaignDetails } = useSelector(selectCampaign);
  const { dynamicListAll, totalCampaignList } = useSelector(selectDynamicList);
  const { staticListSummaryList } = useSelector(selectStaticList);

  const isCampaignVariant =
    variant === LIST_SELECTION_DROPDOWN_VARIANT.CAMPAIGN;
  const LIST_TYPE = isCampaignVariant
    ? MEMBERSHIP_ASSET_TYPES
    : INCLUSION_LIST_TYPES;

  useEffect(() => {
    switch (variant) {
      case LIST_SELECTION_DROPDOWN_VARIANT.CAMPAIGN:
        dispatch(listAllCampaigns());
        break;
      case LIST_SELECTION_DROPDOWN_VARIANT.DYNAMIC_LIST:
        dispatch(listAllDynamicLists());
        break;
    }
    dispatch(listStaticListSummaryList());
  }, [dispatch, variant]);

  const campaignOptions: AssetField[] = useMemo(() => {
    if (isSuccess(totalCampaignList.loading)) {
      const data = totalCampaignList.data
        .filter(
          (item) =>
            campaignDetails.data.campaign_id !== item.campaign_id &&
            (item.activated_at || item.deactivated_at)
        )
        .map((item) => {
          return {
            name: item.name,
            id: item.campaign_id,
            type: LIST_TYPE.DYNAMIC,
          };
        });
      return data;
    }
    return [];
  }, [campaignDetails.data.campaign_id, totalCampaignList, LIST_TYPE.DYNAMIC]);

  const staticListOptions: AssetField[] = useMemo(() => {
    if (isSuccess(staticListSummaryList.loading)) {
      const data = staticListSummaryList.data.map((item) => {
        return {
          name: item.name,
          id: item.static_list_id,
          type: LIST_TYPE.STATIC,
        };
      });
      return data;
    }
    return [];
  }, [staticListSummaryList, LIST_TYPE.STATIC]);

  const dynamicListOptions: AssetField[] = useMemo(
    () =>
      isSuccess(dynamicListAll.loading)
        ? dynamicListAll.data.map((item) => {
            return {
              name: item.name,
              id: item.dynamic_list_id,
              type: LIST_TYPE.DYNAMIC,
            };
          })
        : [],
    [dynamicListAll, LIST_TYPE.DYNAMIC]
  );

  const listOptions: AssetFieldWithPreview[] = useMemo(
    () =>
      [
        ...(isCampaignVariant ? campaignOptions : dynamicListOptions),
        ...staticListOptions,
      ]
        .sort((a: AssetField, b: AssetField) => a.name.localeCompare(b.name))
        .map((option) => {
          return {
            ...option,
            previewAsset: onPreviewAsset,
          };
        }),
    [
      campaignOptions,
      staticListOptions,
      dynamicListOptions,
      isCampaignVariant,
      onPreviewAsset,
    ]
  );
  const listLoading =
    loading ||
    (isCampaignVariant
      ? isLoading(totalCampaignList.loading)
      : isLoading(dynamicListAll.loading)) ||
    isLoading(staticListSummaryList.loading);

  if (isReadOnly) {
    return (
      <Box maxW="60%">
        <AssetNameWithTag
          isReadOnly={isReadOnly}
          data={listOptions.find((option) => option.id === value) ?? null}
          quotes
        />
      </Box>
    );
  } else {
    return (
      <DropdownWithSearch
        options={listOptions}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={listOptions.find((option) => option.id === value) ?? null}
        onChange={(val: SingleValue<AssetFieldWithPreview>) => onChange(val)}
        components={{
          Option: CustomAssetBadgeOption,
          SingleValue: CustomSingleValue,
        }}
        isSearchable
        isClearable={!isCampaignVariant}
        isLoading={listLoading}
        isInvalid={isInvalid}
        controlStyle={{
          height: "32px",
          minHeight: "32px",
          width: "100%",
        }}
        containerStyle={{
          flex: 1,
          width: "fit-content",
          maxWidth: "800px",
          minWidth: "200px",
        }}
      />
    );
  }
}

export function SearchFieldWithCount({
  isLoading,
  count,
  total,
  searchKeyword,
  onSearch,
  searchBarIsHidden,
  children,
}: {
  isLoading: boolean;
  count: number;
  total: number;
  searchKeyword: string;
  onSearch: (keyword: string) => void;
  searchBarIsHidden?: boolean;
  children?: ReactNode;
}) {
  return (
    <>
      {!isLoading && (
        <Text fontSize="sm" fontWeight="normal" display="flex">
          Previewing
          <Text as="span" color="blue.400" fontWeight="semibold" mx="1">
            {convertToString(count)}
          </Text>
          of
          <Text as="span" color="blue.400" fontWeight="semibold" mx="1">
            {convertToString(total)}
          </Text>
          contacts
        </Text>
      )}
      <HStack width="350px" position="absolute" right={12} top={2.5}>
        <SearchField
          name="search-static-list"
          value={searchKeyword}
          onSearch={onSearch}
          hidden={searchBarIsHidden}
        />
        {children}
      </HStack>
    </>
  );
}

export function StaticListPreviewModal({
  staticListId,
  isOpen,
  onClose,
}: {
  staticListId: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { staticListContacts } = useSelector(selectStaticList);

  const dispatch = useAppDispatch();

  const [searchKeyword, setSearchKeyword] = useState("");

  const searchIfValid = useCallback(
    (searchText: string) => {
      if (staticListId)
        dispatch(
          listStaticListContacts({
            id: staticListId,
            searchKeyword: searchText,
            pageNo: 1,
          })
        );
    },
    [dispatch, staticListId]
  );

  useEffect(() => {
    searchIfValid("");
  }, [searchIfValid]);

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function onSearch(keyword: string) {
    setSearchKeyword(keyword);
    debouncedSearch(keyword);
  }

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      header={{ title: "Static list preview" }}
      size="6xl"
      headerChildren={
        <SearchFieldWithCount
          isLoading={staticListContacts.fetchingList}
          count={staticListContacts.list?.length ?? 0}
          total={staticListContacts.count ?? 0}
          searchKeyword={searchKeyword}
          onSearch={onSearch}
        />
      }
    >
      <Box height="70vh">
        <DataTable
          fetchingList={staticListContacts.fetchingList}
          changingPage={staticListContacts.changingPage}
          list={staticListContacts.list}
          totalPageSize={staticListContacts.pageSize}
          totalPageCount={staticListContacts.totalPageCount}
          currentPage={staticListContacts.currentPageNo}
          setPage={(pageNo) => {}}
          columns={staticListContactsPreviewTableHeaderColumnDef}
          emptyMsg="No contacts found"
          isPagination={false}
        />
      </Box>
    </IModal>
  );
}

export default function ListSelectionDropdownWithPreview({
  variant,
  value,
  onChange,
  isInvalid,
  isReadOnly,
  isLoading,
}: {
  variant: LIST_SELECTION_DROPDOWN_VARIANT;
  value: string | number | null;
  onChange: (id: AssetField | null) => void;
  isLoading?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
}) {
  const isCampaignVariant =
    variant === LIST_SELECTION_DROPDOWN_VARIANT.CAMPAIGN;
  const LIST_TYPE = isCampaignVariant
    ? MEMBERSHIP_ASSET_TYPES
    : INCLUSION_LIST_TYPES;

  const [selectedAssetForPreview, setSelectedAssetForPreview] =
    useState<AssetFieldWithPreview | null>(null);

  function onPreviewAsset(data: AssetFieldWithPreview) {
    setSelectedAssetForPreview(data);
  }

  return (
    <>
      <ListSelectionDropdown
        variant={variant}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        isReadOnly={isReadOnly}
        loading={isLoading}
        onPreviewAsset={onPreviewAsset}
      />

      {selectedAssetForPreview?.id &&
        selectedAssetForPreview?.type === LIST_TYPE.STATIC && (
          <StaticListPreviewModal
            isOpen={!!selectedAssetForPreview}
            onClose={() => setSelectedAssetForPreview(null)}
            staticListId={selectedAssetForPreview.id}
          />
        )}

      {selectedAssetForPreview?.id &&
        selectedAssetForPreview?.type === LIST_TYPE.DYNAMIC && (
          <DynamicListPreview
            isOpen={!!selectedAssetForPreview}
            onClose={() => setSelectedAssetForPreview(null)}
            dynamicListId={
              isCampaignVariant ? undefined : selectedAssetForPreview.id
            }
            campaignId={
              isCampaignVariant ? selectedAssetForPreview.id : undefined
            }
          />
        )}
    </>
  );
}
