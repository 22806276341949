import {
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RECIPIENT_EVENT_TYPE } from "../../../../common/constants/campaign";
import { useDebouncedSearch } from "../../../../common/hooks/commonHooks";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";
import { DataTable } from "../../../../components/data-table/DataTable";
import IButton, { BUTTON } from "../../../../components/IButton";
import ITitle from "../../../../components/ITitle";
import { SearchField } from "../../../../components/SearchField";
import { useAppDispatch } from "../../../../store";
import { selectCampaign } from "../campaignSlice";
import { exportCampaignReport, selectExport } from "../../export/exportSlice";
import {
  selectCampaignReports,
  setRecipientEventType,
  setRecipientsReportsPage,
  getRecipientAllData,
  getRecipientBounceData,
  getRecipientClickData,
  getRecipientNotSentData,
  getRecipientOpenData,
  getRecipientSpamData,
  getRecipientUnsubData,
} from "./campaignReportsSlice";
import {
  userColumnDef,
  bounceTableHeader,
  clickColumnDef,
  notSentTableHeader,
  openTableHeader,
  recipientTableHeader,
  spamTableHeader,
  unsubscribedTableHeader,
  FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
} from "./constantHelper";
import { isLoading } from "../../../../common/helper/commonHelper";

function RecipientTable({
  type,
  searchKeyword,
  showUserColumn,
}: {
  type: RECIPIENT_EVENT_TYPE;
  searchKeyword: string;
  showUserColumn: boolean;
}) {
  const dispatch = useAppDispatch();
  const { recipientData } = useSelector(selectCampaignReports);
  const [viewLinks, setViewLinks] = useState<string[]>([]);

  function ViewLinkButton({ links }: { links: string[] }) {
    return (
      <IButton
        variant={BUTTON.SECONDARY}
        onClick={() => setViewLinks(links)}
        fontSize="12px"
        ml={3}
        height="30px"
        name="view-links"
      >
        View links
      </IButton>
    );
  }

  const columns: Record<string, ColumnDef<any>[]> = {
    [RECIPIENT_EVENT_TYPE.ALL]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...openTableHeader,
      ...clickColumnDef(ViewLinkButton),
    ],
    [RECIPIENT_EVENT_TYPE.NOT_SENT]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...notSentTableHeader,
    ],
    [RECIPIENT_EVENT_TYPE.OPEN]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...openTableHeader,
      ...clickColumnDef(ViewLinkButton),
    ],
    [RECIPIENT_EVENT_TYPE.CLICK]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...clickColumnDef(ViewLinkButton),
    ],
    [RECIPIENT_EVENT_TYPE.BOUNCE]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...bounceTableHeader,
    ],
    [RECIPIENT_EVENT_TYPE.UNSUB]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...unsubscribedTableHeader,
    ],
    [RECIPIENT_EVENT_TYPE.SPAM]: [
      ...userColumnDef(showUserColumn),
      ...recipientTableHeader,
      ...spamTableHeader,
    ],
  };

  function ViewLinksModal() {
    return (
      <Modal
        isOpen={!!viewLinks.length}
        onClose={() => setViewLinks([])}
        isCentered={true}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="18px">Link preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
            <VStack maxHeight="70vh" overflow="auto">
              {viewLinks.map((link, index) => {
                return (
                  <Box
                    w="100%"
                    key={index}
                    bg="gray.200"
                    p={1}
                    borderRadius="2px"
                  >
                    {link}
                  </Box>
                );
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box h="calc(100vh - 290px)">
      <DataTable
        fetchingList={recipientData[type].fetchingList}
        changingPage={recipientData[type].changingPage}
        list={recipientData[type].list}
        totalPageSize={recipientData[type].pageSize}
        setPage={(pageNo) => dispatch(setRecipientsReportsPage(pageNo))}
        totalPageCount={recipientData[type].totalPageCount}
        currentPage={recipientData[type].currentPageNo}
        columns={columns[type]}
        emptyMsg={`No contacts found.${
          searchKeyword ? " Please change the search / filter values" : ""
        }`}
      />
      <ViewLinksModal />
    </Box>
  );
}

export default function RecipientsReports() {
  const [searchKeyword, setSearchKeyword] = useState("");

  const dispatch = useAppDispatch();

  const {
    campaignDetails: {
      data: { campaign_context, campaign_id: campaignId },
    },
  } = useSelector(selectCampaign);

  const {
    recipientData,
    recipientEventType,
    isReportsDrawerOpen,
    filterData: { timeRange, actionId },
  } = useSelector(selectCampaignReports);

  const { assetsToExport } = useSelector(selectExport);

  const currentPageNoOfRecipientEvent =
    recipientData[recipientEventType].currentPageNo;

  const isExportLoading =
    isLoading(assetsToExport[campaignId + recipientEventType]?.isLoading) ||
    recipientData[recipientEventType].fetchingList;

  useEffect(() => {
    loadRecipientData({ searchKeyword });
    // eslint-disable-next-line
  }, [
    dispatch,
    currentPageNoOfRecipientEvent,
    recipientEventType,
    timeRange,
    actionId,
  ]);

  function switchTabData(type: RECIPIENT_EVENT_TYPE) {
    dispatch(setRecipientEventType(type));
  }

  function exportReportList() {
    dispatch(
      exportCampaignReport({
        campaignId,
        actionId: actionId ?? undefined,
        recipientType: recipientEventType,
        searchQuery: searchKeyword,
        dateRange: timeRange,
      })
    );
  }

  const loadRecipientData = useCallback(
    ({ searchKeyword = "" }) => {
      if (isReportsDrawerOpen && timeRange.startDate && timeRange.endDate) {
        switch (recipientEventType) {
          case RECIPIENT_EVENT_TYPE.ALL:
            dispatch(
              getRecipientAllData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
          case RECIPIENT_EVENT_TYPE.NOT_SENT:
            dispatch(
              getRecipientNotSentData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
          case RECIPIENT_EVENT_TYPE.OPEN:
            dispatch(
              getRecipientOpenData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
          case RECIPIENT_EVENT_TYPE.CLICK:
            dispatch(
              getRecipientClickData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
          case RECIPIENT_EVENT_TYPE.BOUNCE:
            dispatch(
              getRecipientBounceData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
          case RECIPIENT_EVENT_TYPE.UNSUB:
            dispatch(
              getRecipientUnsubData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
          case RECIPIENT_EVENT_TYPE.SPAM:
            dispatch(
              getRecipientSpamData({
                searchKeyword,
                columnsToSearchIn: FILTER_COLUMNS_FOR_RECIPIENT_SEARCH,
              })
            );
            break;
        }
      }
    },
    [
      dispatch,
      isReportsDrawerOpen,
      recipientEventType,
      timeRange.endDate,
      timeRange.startDate,
    ]
  );

  const searchIfValid = useCallback(
    (searchKeyword: string) => {
      if (currentPageNoOfRecipientEvent !== 1)
        dispatch(setRecipientsReportsPage(1));
      else loadRecipientData({ searchKeyword });
    },
    [dispatch, loadRecipientData, currentPageNoOfRecipientEvent]
  );

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  function onSearchKeywordChange(text: string) {
    setSearchKeyword(text);
    debouncedSearch(text);
  }

  return (
    <Tabs size="md" variant="enclosed-colored" mt={10} width="100%">
      <HStack>
        <TabList borderBottom={1}>
          {Object.entries(RECIPIENT_EVENT_TYPE).map(([key, value], index) => (
            <Tab
              key={`${key}${index}`}
              onClick={() => switchTabData(value as RECIPIENT_EVENT_TYPE)}
            >
              <ITitle title={key.replaceAll("_", " ")} />
            </Tab>
          ))}
        </TabList>
        <Spacer />
        <SearchField
          placeholder="Search recipient"
          name="search-input"
          value={searchKeyword}
          onSearch={onSearchKeywordChange}
          width="250px"
        />
        <Tooltip
          label="Can't export empty list"
          isDisabled={
            !!recipientData[recipientEventType].count || isExportLoading
          }
        >
          <IButton
            onClick={exportReportList}
            isLoading={isExportLoading}
            isDisabled={!recipientData[recipientEventType].count}
          >
            Export List
          </IButton>
        </Tooltip>
      </HStack>
      <Divider />
      <TabPanels>
        {Object.values(RECIPIENT_EVENT_TYPE).map((value, index) => {
          return (
            <TabPanel key={`${value}${index}`} px={0}>
              <RecipientTable
                type={value}
                searchKeyword={searchKeyword}
                showUserColumn={campaign_context === CAMPAIGN_CONTEXT.ORG}
              />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
}
