import {
  Center,
  Container,
  Accordion,
  AccordionItem,
  Alert,
  AccordionButton,
  Icon,
  Box,
  Flex,
  AccordionIcon,
  AccordionPanel,
  VStack,
  Heading,
  Code,
  Button,
  Text,
} from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa";
import { FallbackProps } from "react-error-boundary";

function ErrorBlock({ title, code }: { title: string; code: string }) {
  return (
    <VStack alignItems="left" mb="4">
      <Heading as="h5" fontSize="sm">
        {title}
      </Heading>
      <Code
        p="3"
        rounded="sm"
        fontSize={"xs"}
        maxHeight="400px"
        overflow="auto"
      >
        {code}
      </Code>
    </VStack>
  );
}

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (
    <Center height={"100vh"}>
      <Container
        display="flex"
        flexDir={"column"}
        size="lg"
        justifyContent={"center"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <Alert status="error" rounded="sm">
                  <AccordionButton>
                    <Icon as={FaExclamationCircle} color="red.500" />
                    <Box ml="2" flex="1" textAlign="left">
                      There was an error processing your request
                    </Box>
                    <Flex alignItems="center">
                      <Text fontSize="xs">{isExpanded ? "Less" : "More"}</Text>
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>
                </Alert>
                <AccordionPanel pb={4} bg="red.50">
                  <Flex flexDir="column">
                    <VStack alignItems="left">
                      <ErrorBlock title="Error" code={error.toString()} />
                    </VStack>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        <Button
          mt="3"
          variant={"link"}
          color="brand.blue"
          onClick={() => {
            window.location.reload();
            resetErrorBoundary();
          }}
        >
          Back
        </Button>
      </Container>
    </Center>
  );
}
