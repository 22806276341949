import { useMemo } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import ErrorBoundaryLayout from "../../components/ErrorBoundaryLayout";
import LayoutFullscreen from "../../layout/LayoutFullScreen";
import urls from "../../urls";
import { routes as accountRoutes } from "./accountRoutes";

export default function Landing() {
  const routesData = useMemo(
    () => (
      <Route element={<ErrorBoundaryLayout />}>
        <Route path="/" element={<LayoutFullscreen />}>
          {accountRoutes.map(({ path, component: Component }, i) => {
            return <Route key={i} path={path} element={<Component />} />;
          })}
          <Route path="*" element={<Navigate replace to={urls.login} />} />
        </Route>
      </Route>
    ),
    []
  );

  const routes = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements([
          <Route path="/" element={<Navigate replace to={urls.login} />} />,
          routesData,
        ])
      ),
    [routesData]
  );

  return <RouterProvider router={routes} />;
}
