import { useSelector } from "react-redux";
import { selectFeatureFlag } from "../../../../common/slices/featureFlagSlice";
import { CONTACTS_INGESTION_SERVICE } from "../../../../common/types/featureFlag";
import { isFailed, isLoading } from "../../../../common/helper/commonHelper";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useNavigate } from "react-router-dom";
import urls from "../../../../urls";
import SalesforceLegacyDetails from "../salesforceLegacyService/SalesforceLegacyDetails";
import SalesforceConnection from "../salesforce/SalesforceConnection";
import { useContactIngestionApiRetry } from "../../../../common/hooks/commonHooks";
import { toast } from "react-toastify";
import { useEffect } from "react";

function SalesforceConnectionComponent({
  etl,
}: {
  etl: CONTACTS_INGESTION_SERVICE | null;
}) {
  switch (etl) {
    case CONTACTS_INGESTION_SERVICE.LEGACY:
      return <SalesforceLegacyDetails />;
    case CONTACTS_INGESTION_SERVICE.FLINK:
      return <SalesforceConnection />;
  }
  return <></>;
}

export default function SalesforceConnectionDetails() {
  const {
    contactIngestionEtl: { data, loading },
  } = useSelector(selectFeatureFlag);
  const navigate = useNavigate();

  useContactIngestionApiRetry();

  useEffect(() => {
    if (isFailed(loading)) {
      toast.error("Failed to access salesforce page");
      navigate(urls.unifiedMapping);
    }
  }, [loading, navigate]);

  return (
    <SpinnerContainer loading={isLoading(loading)} h="90vh">
      <SalesforceConnectionComponent etl={data} />
    </SpinnerContainer>
  );
}
