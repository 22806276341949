import { Badge, Flex, HStack, Text } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { CAMPAIGN_RUN_STATE } from "../../../../common/constants/campaign";
import {
  BounceReasonPerContact,
  ClickData,
  DateActionIdFilter,
  LinkClickData,
  OpenData,
  RecipientBounce,
  RecipientData,
  RecipientNotSent,
  RecipientUnsub,
  RecipientWithUrls,
  RECIPIENT_TYPES,
  ReportsFilterApiType,
  ReportsFilterType,
  RunStats,
} from "../../../../common/types/campaign";
import WrapperWithSkeleton from "../../../../components/WrapperWithSkeleton";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import {
  convertToString,
  isLoading,
  truncateTextAboveLimit,
} from "../../../../common/helper/commonHelper";
import { RedirectToContact } from "../../../../components/RedirectToPage";
import { SearchAssetsType } from "../../../../common/types/common";
import { EMPTY_STRING } from "../../../../common/constants/common";

export const recipientTableHeader: ColumnDef<RecipientData>[] = [
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Name",
    cell: (info) =>
      info.row.original.name ??
      `${info.row.original.first_name ?? ""} ${
        info.row.original.last_name ?? ""
      }`,
  },
  {
    header: "Date Sent",
    accessorKey: "sent_date",
    cell: (info) => <FormatDate date={info.getValue() as string} showTime />,
  },
];

export function clickColumnDef(
  ViewLinkButton: ({ links }: { links: string[] }) => JSX.Element
): ColumnDef<RecipientWithUrls & ClickData>[] {
  return [
    {
      header: "Links clicked",
      cell: (info) => (
        <Flex alignItems="center">
          {info.row.original.unique_click_count_by_url}{" "}
          {info.row.original.urls && !!info.row.original.urls.length && (
            <ViewLinkButton links={info.row.original.urls} />
          )}
        </Flex>
      ),
    },
  ];
}

export const openTableHeader: ColumnDef<OpenData | {}>[] = [
  {
    header: "Opens",
    accessorKey: "unique_open_count",
    meta: {
      isNumeric: true,
    },
  },
];

export const notSentTableHeader: ColumnDef<RecipientNotSent>[] = [
  {
    header: "Reason for not sent",
    accessorKey: "error_text",
    size: 500,
  },
];

export const bounceTableHeader: ColumnDef<RecipientBounce>[] = [
  {
    header: "Bounce details",
    accessorKey: "reason",
    size: 500,
  },
];

export const unsubscribedTableHeader: ColumnDef<RecipientUnsub>[] = [
  {
    header: "Date unsubscribed",
    accessorKey: "unsub_time",
    cell: (info) => <FormatDate date={info.getValue() as string} showTime />,
  },
];

export const spamTableHeader: ColumnDef<RecipientBounce>[] = [
  {
    header: "Date of spam report",
    accessorKey: "event_time",
    cell: (info) => <FormatDate date={info.getValue() as string} showTime />,
  },
];

export const topLinkHeader: ColumnDef<LinkClickData>[] = [
  {
    header: "Link",
    accessorKey: "link",
    size: 750,
  },
  {
    header: "Number of Clicks",
    accessorKey: "unique_click_count_by_url",
    meta: {
      isNumeric: true,
    },
  },
];

function getEmailDisplayForBounceClassification({
  email,
  recipientType,
  personId,
}: {
  email: string;
  recipientType: RECIPIENT_TYPES;
  personId: string;
}) {
  switch (recipientType) {
    case RECIPIENT_TYPES.TO:
      return <RedirectToContact email={email} personId={personId} />;
    case RECIPIENT_TYPES.CC_BCC:
      return (
        <RedirectToContact
          email={
            <HStack>
              <Text>{email}</Text>
              <Badge
                color="blue.600"
                bg="gray.100"
                textTransform="none"
                textAlign="center"
                w="60px"
                rounded={6}
                px={2}
                py={1}
              >
                Cc/Bcc
              </Badge>
            </HStack>
          }
          personId={EMPTY_STRING}
        />
      );
  }
}

export const bounceClassificationListHeader: ColumnDef<BounceReasonPerContact>[] =
  [
    {
      header: "Email",
      accessorKey: "email",
      meta: {
        width: "200px",
      },
      cell: (info) =>
        getEmailDisplayForBounceClassification({
          email: info.cell.row.original.email,
          recipientType: info.cell.row.original.recipient_type,
          personId: info.cell.row.original.person_id,
        }),
    },
    {
      header: "Date sent",
      accessorKey: "sent_time",
      cell: (info) => <FormatDate date={info.getValue() as string} showTime />,
    },
    {
      header: "Reason",
      accessorKey: "reason",
      // !info: truncated since old data was stored in the db with 100 char limit and adding ellipsis to look better
      cell: (info) => (
        <Text>{truncateTextAboveLimit(info.getValue() as string, 99)}</Text>
      ),
    },
  ];

export function userColumnDef(add: boolean): ColumnDef<RecipientData>[] {
  if (add) {
    return [
      {
        header: "User",
        cell: (info) => (
          <Flex alignItems="center">
            {`${info.row.original.product_user_id} ${
              info.row.original.org_id ? `(${info.row.original.org_id})` : ""
            }`}
          </Flex>
        ),
      },
    ];
  }
  return [];
}

const STATS_COLORS = {
  [CAMPAIGN_RUN_STATE.FINISHED]: { display: "Finished", color: "green" },
  [CAMPAIGN_RUN_STATE.CANCELED]: { display: "Canceled", color: "red" },
  [CAMPAIGN_RUN_STATE.PROCESSING]: { display: "In Progress", color: "yellow" },
  [CAMPAIGN_RUN_STATE.SCHEDULED]: { display: "Scheduled", color: "blue" },
  [CAMPAIGN_RUN_STATE.SKIPPED]: { display: "Skipped", color: "gray" },
};

export const FILTER_COLUMNS_FOR_RECIPIENT_SEARCH = ["email", "name"];

export const campaignHistoryTableHeader: ColumnDef<RunStats>[] = [
  {
    header: "Time",
    accessorKey: "start_time",
    cell: (info) => <FormatDate date={info.getValue() as string} showTime />,
  },
  {
    header: "Emails processed",
    accessorKey: "total_count",
    meta: {
      isNumeric: true,
    },
    cell: (info) => (
      <WrapperWithSkeleton loading={isLoading(info.row.original.stats_loading)}>
        {convertToString(info.getValue() as number)}
      </WrapperWithSkeleton>
    ),
  },
  {
    header: "Delivered",
    accessorKey: "delivered_count",
    meta: {
      isNumeric: true,
    },
    cell: (info) => (
      <WrapperWithSkeleton loading={isLoading(info.row.original.stats_loading)}>
        {convertToString(info.getValue() as number)}
      </WrapperWithSkeleton>
    ),
  },
  {
    header: "Opened",
    accessorKey: "unique_open_count",
    meta: {
      isNumeric: true,
    },
    cell: (info) => (
      <WrapperWithSkeleton loading={isLoading(info.row.original.stats_loading)}>
        {convertToString(info.getValue() as number)}
      </WrapperWithSkeleton>
    ),
  },
  {
    header: "Clicked",
    accessorKey: "unique_click_count_by_email",
    meta: {
      isNumeric: true,
    },
    cell: (info) => (
      <WrapperWithSkeleton loading={isLoading(info.row.original.stats_loading)}>
        {convertToString(info.getValue() as number)}
      </WrapperWithSkeleton>
    ),
  },
  {
    header: "Bounced",
    accessorKey: "bounce_count",
    meta: {
      isNumeric: true,
    },
    cell: (info) => (
      <WrapperWithSkeleton loading={isLoading(info.row.original.stats_loading)}>
        {convertToString(info.getValue() as number)}
      </WrapperWithSkeleton>
    ),
  },
  {
    header: "Unsubscribed",
    accessorKey: "unsub_count",
    meta: {
      isNumeric: true,
    },
    cell: (info) => (
      <WrapperWithSkeleton loading={isLoading(info.row.original.stats_loading)}>
        {convertToString(info.getValue() as number)}
      </WrapperWithSkeleton>
    ),
  },
  {
    header: "Run state",
    accessorKey: "state",
    cell: (info) => {
      const state = info.getValue() as CAMPAIGN_RUN_STATE;
      return (
        <Badge
          fontSize="xs"
          colorScheme={STATS_COLORS[state]?.color || "gray"}
          variant="solid"
        >
          {STATS_COLORS[state]?.display || state}
        </Badge>
      );
    },
  },
];

export function filterActionIdIfExist(filter: ReportsFilterType) {
  let newFilter: ReportsFilterApiType = {
    campaign_id: filter.campaignId,
    start_date: filter.timeRange.startDate,
    end_date: filter.timeRange.endDate,
  };
  if (filter.actionId) newFilter.action_id = filter.actionId;
  return newFilter;
}

export function removeActionIdIfEmpty(filter: ReportsFilterType) {
  let newFilter: DateActionIdFilter = {
    startDate: filter.timeRange.startDate,
    endDate: filter.timeRange.endDate,
  };
  if (filter.actionId) newFilter.actionId = filter.actionId;
  return newFilter;
}

export function transformSearchFilterToApiQuery({
  searchKeyword,
  columnsToSearchIn,
}: SearchAssetsType) {
  return {
    query: {
      search: {
        keyword: searchKeyword,
        fields: columnsToSearchIn,
      },
    },
  };
}
