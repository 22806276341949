import {
  HStack,
  Text,
  VStack,
  Link,
  Divider,
  Icon,
  Box,
} from "@chakra-ui/react";
import {
  SfSyncAffectedRecord,
  SF_SYNC_ERROR_TYPE,
  SfSyncErrorRecord,
} from "../../../../../../common/types/salesforce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../../../store";
import {
  listSalesforceSyncAffectedRecords,
  selectSalesforce,
  setAffectedRecordsPage,
} from "../../salesforceSlice";
import { useSelector } from "react-redux";
import {
  openSalesforceInstance,
  isValidUrl,
  isLoading,
  getEpochTimeUtc,
  convertToString,
} from "../../../../../../common/helper/commonHelper";
import { FaExternalLinkAlt } from "react-icons/fa";
import SubHeader from "../../../components/SubHeader";
import { SearchField } from "../../../../../../components/SearchField";
import TableEntryText from "../../../../../../components/TableEntryText";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../../../components/DateTimeRangeFilter";
import urls from "../../../../../../urls";
import { useDebouncedSearch } from "../../../../../../common/hooks/commonHooks";
import MemoizedCommonDrawer from "../../../../campaign/components/CommonDrawer";

const errorTypeMessage: {
  [key in SF_SYNC_ERROR_TYPE]: { errorTypeDetails: string };
} = {
  [SF_SYNC_ERROR_TYPE.OTHERS]: {
    errorTypeDetails:
      "The records listed below aren't syncing because of an unknown error. Please review the error details to debug the issue",
  },
  [SF_SYNC_ERROR_TYPE.PERMISSION]: {
    errorTypeDetails:
      "These Inflection records couldn't sync to Salesforce because the integration user doesn't have the required Salesforce field permissions",
  },
  [SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC]: {
    errorTypeDetails: "",
  },
};

function ErrorModalHeader({
  searchKeyword,
  onSearch,
  totalRecords,
}: {
  searchKeyword: string;
  onSearch: (keyword: string) => void;
  totalRecords: number;
}) {
  return (
    <SubHeader title={`Affected Records (${convertToString(totalRecords)})`}>
      <SearchField
        value={searchKeyword}
        name="search-input"
        onSearch={onSearch}
        w="250px"
      />
    </SubHeader>
  );
}

export default function AffectedErrorRecordsModal({
  isOpen,
  errorType,
  data,
  onClose,
  filterDates: [startDate, endDate],
}: {
  isOpen: boolean;
  onClose: () => void;
  errorType: SF_SYNC_ERROR_TYPE;
  data: SfSyncErrorRecord | null;
  filterDates: [Date, Date];
}) {
  const dispatch = useAppDispatch();
  const {
    sfSyncErrorAffectedRecords: {
      loadingList,
      list,
      count,
      pageSize,
      currentPageNo,
      totalPageCount,
      changingPage,
    },
    sfConnection,
  } = useSelector(selectSalesforce);

  const [searchKeyword, setSearchKeyword] = useState("");

  const redirectSalesforceWindow = useCallback(
    (row: SfSyncAffectedRecord) => {
      openSalesforceInstance({
        url: sfConnection?.instanceUrl as string,
        crmObject: "users",
        salesforceId: row.salesforceId,
      });
    },
    [sfConnection?.instanceUrl]
  );

  function onModalClose() {
    dispatch(setAffectedRecordsPage(1));
    setSearchKeyword("");
    onClose();
  }

  const isInstanceUrlValid: boolean = useMemo(
    () =>
      !!sfConnection &&
      (isValidUrl(sfConnection.instanceUrl) ||
        isValidUrl("https://" + sfConnection.instanceUrl)),
    [sfConnection]
  );

  useEffect(() => {
    if (data && sfConnection?.connectionId) {
      dispatch(
        listSalesforceSyncAffectedRecords({
          searchQuery: searchKeyword,
          connectionId: sfConnection.connectionId,
          errorClass: data.errorClass,
          startTime: getEpochTimeUtc(startDate),
          endTime: getEpochTimeUtc(endDate),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    data,
    currentPageNo,
    sfConnection?.connectionId,
    startDate,
    endDate,
  ]);

  const columnHelper = createColumnHelper<SfSyncAffectedRecord>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        size: 300,
        cell: (info) => <Text>{info.getValue() ?? "Not Available"}</Text>,
      }),
      columnHelper.accessor("errorMessage", {
        header: "Error details",
        size: 440,
        cell: (info) => (
          <TableEntryText>{info.getValue() ?? "Not Available"}</TableEntryText>
        ),
      }),
      columnHelper.accessor("inflectionId", {
        header: "View record on",
        cell: (info) => {
          return (
            <HStack>
              {info.getValue() ? (
                <Link
                  href={`${urls.person}/${info.getValue()}`}
                  color="teal.300"
                >
                  Inflection Link
                  <Icon fontSize="17px" pt="2px" ml="2px">
                    <FaExternalLinkAlt />
                  </Icon>
                </Link>
              ) : (
                <Text>Not Available</Text>
              )}
              <Divider
                orientation="vertical"
                borderColor="blackAlpha.400"
                height="20px"
                mx="2px"
              />
              {isInstanceUrlValid && info.row.original.salesforceId ? (
                <Link
                  onClick={() => redirectSalesforceWindow(info.row.original)}
                  color="blue.600"
                >
                  Salesforce Link
                  <Icon fontSize="17px" pt="2px" ml="2px">
                    <FaExternalLinkAlt />
                  </Icon>
                </Link>
              ) : (
                <Text>Not Available</Text>
              )}
            </HStack>
          );
        },
        size: 400,
      }),
      columnHelper.accessor("createdAt", {
        header: "Error date",
        cell: (info) => <FormatDate date={info.getValue()} />,
        size: 250,
      }),
    ],
    [columnHelper, isInstanceUrlValid, redirectSalesforceWindow]
  );

  const searchIfValid = useCallback(
    (searchKeyword: string) => {
      if (currentPageNo !== 1) {
        dispatch(setAffectedRecordsPage(1));
      } else {
        if (data && sfConnection?.connectionId) {
          dispatch(
            listSalesforceSyncAffectedRecords({
              searchQuery: searchKeyword,
              connectionId: sfConnection?.connectionId,
              errorClass: data.errorClass,
              startTime: getEpochTimeUtc(startDate),
              endTime: getEpochTimeUtc(endDate),
            })
          );
        }
      }
    },
    [
      currentPageNo,
      dispatch,
      sfConnection?.connectionId,
      data,
      startDate,
      endDate,
    ]
  );

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  return (
    <MemoizedCommonDrawer
      title={errorType + " error"}
      isOpen={isOpen}
      onClose={onModalClose}
      size="2xl"
      placement="right"
    >
      <VStack alignItems="flex-start" w="100%">
        <Text>{errorTypeMessage[errorType]?.errorTypeDetails}</Text>
        <Box height="450px" width="100%">
          <ErrorModalHeader
            searchKeyword={searchKeyword}
            onSearch={(keyword: string) => {
              setSearchKeyword(keyword);
              debouncedSearch(keyword);
            }}
            totalRecords={count}
          />
          <DataTable
            fetchingList={isLoading(loadingList)}
            changingPage={changingPage}
            list={list ?? []}
            setPage={(pageNo: number) =>
              dispatch(setAffectedRecordsPage(pageNo))
            }
            totalPageCount={totalPageCount}
            currentPage={currentPageNo}
            totalPageSize={pageSize}
            columns={columns}
            emptyMsg="No entries exist"
            scrollProps={{
              overflowY: "scroll",
              height: "350px",
            }}
            mt="2"
            border="1px"
            borderColor="grayV2.100"
          />
        </Box>
      </VStack>
    </MemoizedCommonDrawer>
  );
}
