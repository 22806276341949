import { Avatar, Divider, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { SCHEDULE_TYPES } from "../../../../common/constants/campaign";
import {
  checkIsAccountInactive,
  getUserStatusFromMapping,
  isLoading,
} from "../../../../common/helper/commonHelper";
import { USER_ACCOUNT_STATUS } from "../../../../common/types/auth";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import LabelValuePair from "../../../../components/LabelValuePair";
import { useAppDispatch } from "../../../../store";
import { getUserList, selectAccount } from "../../../account/accountSlice";
import { selectCampaign } from "../campaignSlice";
import { selectCampaignReports } from "./campaignReportsSlice";

export default function CampaignDetails() {
  const {
    campaignDetails: {
      data: campaignDetails,
      loading: isCampaignDetailsLoading,
    },
  } = useSelector(selectCampaign);
  const { usersList } = useSelector(selectAccount);
  const {
    filterData: { actionId },
  } = useSelector(selectCampaignReports);

  const dispatch = useAppDispatch();

  const userStatus: USER_ACCOUNT_STATUS = useMemo(
    () =>
      campaignDetails.created_by.id
        ? getUserStatusFromMapping(
            campaignDetails.created_by.id.toString(),
            usersList
          )
        : USER_ACCOUNT_STATUS.PENDING,
    [campaignDetails.created_by.id, usersList]
  );

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch, usersList]);

  const isUserInactive = checkIsAccountInactive(userStatus);
  if (campaignDetails.campaign_id && !actionId)
    return (
      <HStack
        alignItems="flex-start"
        rounded="md"
        w="100%"
        py={2}
        pl={8}
        spacing={8}
        divider={
          <Divider
            orientation="vertical"
            h="50px"
            border="1px solid"
            borderColor="gray.300"
          />
        }
        border="1px solid"
        borderColor="gray.300"
      >
        <LabelValuePair
          label="Journey published on"
          value={
            campaignDetails.activated_at && (
              <FormatDate date={campaignDetails.activated_at} showTime />
            )
          }
          isLoadingData={isLoading(isCampaignDetailsLoading)}
        />
        <LabelValuePair
          label="Created by"
          value={
            <Tooltip
              label="The user is inactive"
              fontSize="xs"
              position="initial"
              size="small"
              isDisabled={!isUserInactive}
            >
              <Flex alignItems="center">
                <Avatar
                  mr={2}
                  size="xs"
                  name={campaignDetails.created_by.name}
                  opacity={isUserInactive ? "0.5" : ""}
                />
                <Text color={isUserInactive ? "gray.300" : ""}>
                  {campaignDetails.created_by.name}
                </Text>
              </Flex>
            </Tooltip>
          }
          isLoadingData={isLoading(isCampaignDetailsLoading)}
        />
        <LabelValuePair
          label="Schedule"
          value={
            SCHEDULE_TYPES.find(
              (runType) => runType.value === campaignDetails.schedule?.run_type
            )?.name
          }
          isLoadingData={isLoading(isCampaignDetailsLoading)}
        />
      </HStack>
    );

  return <></>;
}
