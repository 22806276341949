import { useSelector } from "react-redux";
import { selectFeatureFlag } from "../../../../common/slices/featureFlagSlice";
import { CONTACTS_INGESTION_SERVICE } from "../../../../common/types/featureFlag";
import SalesforceLegacySuccess from "../salesforceLegacyService/SalesforceLegacySuccess";
import SalesforceAuthSuccess from "../salesforce/SalesforceAuthSuccess";

import { isFailed, isLoading } from "../../../../common/helper/commonHelper";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useNavigate } from "react-router-dom";
import urls from "../../../../urls";
import { useContactIngestionApiRetry } from "../../../../common/hooks/commonHooks";
import { useEffect } from "react";
import { toast } from "react-toastify";

function SalesforceSuccessComponent({
  etl,
}: {
  etl: CONTACTS_INGESTION_SERVICE | null;
}) {
  switch (etl) {
    case CONTACTS_INGESTION_SERVICE.LEGACY:
      return <SalesforceLegacySuccess />;
    case CONTACTS_INGESTION_SERVICE.FLINK:
      return <SalesforceAuthSuccess />;
  }
  return <></>;
}

export default function SalesforceConnectionSuccess() {
  const {
    contactIngestionEtl: { data: etl, loading },
  } = useSelector(selectFeatureFlag);
  const navigate = useNavigate();

  useContactIngestionApiRetry();

  useEffect(() => {
    if (isFailed(loading)) {
      toast.error("Failed to authenticate salesforce");
      navigate(urls.unifiedMapping, { replace: true });
    }
  }, [loading, navigate]);

  return (
    <SpinnerContainer loading={isLoading(loading)} h="90vh">
      <SalesforceSuccessComponent etl={etl} />
    </SpinnerContainer>
  );
}
