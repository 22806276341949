import { useEffect, useState, useCallback, useMemo } from "react";
import { CommonListHeader } from "../../../components/CommonListHeader";
import {
  listForms,
  selectForm,
  listFormProviders,
  formListSubmissionStats,
  listAllForms,
  resetFormList,
  resetProvidersList,
} from "./formSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { DataTable } from "../../../components/data-table/DataTable";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import {
  Text,
  useDisclosure,
  HStack,
  Image,
  Icon,
  Badge,
  Box,
} from "@chakra-ui/react";
import urls from "../../../urls";
import {
  FORM_STATUS,
  FORM_VENDORS,
  FormDetailsSummary,
} from "../../../common/types/form";
import { createColumnHelper } from "@tanstack/react-table";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import {
  addSuffixForPlural,
  isInit,
  isLoading,
  isFulfilled,
  getTableRowLinkProps,
} from "../../../common/helper/commonHelper";
import SelectFormProvider from "./components/SelectFormProvider";
import WrapperWithSkeleton from "../../../components/WrapperWithSkeleton";
import {
  LOADING_STATES,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import { STATUS } from "../../../common/types/unifiedMapping";
import formsWaiting from "../../../common/img/formsWaiting.png";
import { FaInfoCircle } from "react-icons/fa";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import FormProviderWithLogo from "./components/FormProviderWithLogo";
import { FORM_STATUS_DETAILS } from "./helper";
import { PaginationFilterParams } from "../../../common/types/common";
import InitialEmptyState from "../../../components/InitialEmptyState";
import FormEmptyState from "../../../common/img/emptyStateLogos/forms.svg";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";
import { IS_BEFORE_DEADLINE } from "./components/WebflowVersionDeprecationBanner";

function FormsEmptyState({
  onConnectProvider,
}: {
  onConnectProvider: () => void;
}) {
  return (
    <InitialEmptyState
      mainText="Let's start with connecting to a form provider"
      message="No active forms"
      ctaProps={{
        children: "Connect to a provider",
        name: "connect-provider",
        onClick: onConnectProvider,
      }}
    >
      <Image src={FormEmptyState} alt="forms" />
    </InitialEmptyState>
  );
}

function FormsAwaitingState() {
  return (
    <InitialEmptyState
      mainText="Please wait for the test submission to load onto the system"
      message="No active forms"
    >
      <Image src={formsWaiting} boxSize="130px" w="100px" />
    </InitialEmptyState>
  );
}

function FormName({ name, unmapped }: { name: string; unmapped: boolean }) {
  return (
    <HStack>
      <Text fontSize="sm">{name}</Text>
      <Box>
        {unmapped && (
          <Badge
            textTransform="none"
            fontSize="10px"
            color="orange.400"
            bg="orange.100"
          >
            Unmapped
          </Badge>
        )}
      </Box>
    </HStack>
  );
}

function FormStatus({ status }: { status: FORM_STATUS }) {
  const { text, color } = FORM_STATUS_DETAILS[status];
  return (
    <Text color={color} fontWeight="semibold">
      {text}
    </Text>
  );
}

function AlertWebflowForms() {
  return IS_BEFORE_DEADLINE ? (
    <HStack borderRadius="6px" bg="orange.100" p={2} mx={5} mb={2}>
      <Icon as={FaInfoCircle} fontSize="sm" color="orange.400" />
      <Text fontSize="sm" color="orange.500">
        Please migrate your Webflow V1 API to V2, as V1 will be deprecated on
        January 1, 2025. Ensure a smooth transition to avoid any disruptions in
        your services.
      </Text>
    </HStack>
  ) : (
    <></>
  );
}

function AlertUnmappedForms({ count }: { count: number }) {
  return count ? (
    <HStack borderRadius="6px" bg="orange.100" p={2} mx={5} mb={2}>
      <Icon as={FaInfoCircle} fontSize="sm" color="orange.400" />
      <Text fontSize="sm" color="orange.500">
        {`${count} new unmapped ${addSuffixForPlural("form", count)} found`}
      </Text>
    </HStack>
  ) : (
    <></>
  );
}

export default function FormList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isFormStatusLoading, setIsFormStatusLoading] = useState(
    LOADING_STATES.INIT
  );

  const {
    formsList,
    vendorStatus,
    formProviderDetails: { loading: providerDetailsLoading },
    providersList: { data: providers },
    allFormsSummary: { data: formsSummary },
  } = useSelector(selectForm);

  const fetchFormsList = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listForms({
          pageNo,
          searchKeyword,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    searchKeyword,
    filters,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchFormsList,
    fetchingList: formsList.fetchingList,
  });

  const connectedFormProviders = useMemo(
    () =>
      providers
        .filter((provider) => provider.state !== STATUS.DISABLED)
        .map((provider) => provider.key_type),
    [providers]
  );

  useEffect(() => {
    if (
      !!formsList.list?.length &&
      formsList.list.every(({ submissionStatsLoading }) =>
        isInit(submissionStatsLoading)
      )
    ) {
      dispatch(
        formListSubmissionStats({
          form_ids: formsList.list?.map((form) => form.form_id) ?? [],
          source: "",
        })
      );
    }
  }, [dispatch, formsList.list]);

  const setFormStatus = useCallback(async () => {
    setIsFormStatusLoading(LOADING_STATES.LOADING);
    const {
      meta: { requestStatus },
    } = await dispatch(listFormProviders());

    if (isFulfilled(requestStatus)) {
      setIsFormStatusLoading(LOADING_STATES.SUCCESS);
      return;
    }

    setIsFormStatusLoading(LOADING_STATES.FAILED);
  }, [dispatch]);

  useEffect(() => {
    if (isInit(isFormStatusLoading) && isInit(providerDetailsLoading)) {
      setFormStatus();
    }
  }, [isFormStatusLoading, setFormStatus, providerDetailsLoading]);

  useEffect(() => {
    if (isOpen) {
      dispatch(listFormProviders());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    dispatch(listAllForms());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetFormList());
      dispatch(resetProvidersList());
    };
  }, [dispatch]);

  const isUnmapped = useCallback(
    (formStatus: FORM_STATUS, vendor: FORM_VENDORS) => {
      return (
        formStatus === FORM_STATUS.MAPPING_PENDING &&
        vendorStatus?.[vendor] !== STATUS.DISABLED
      );
    },
    [vendorStatus]
  );

  const totalUnmappedForms = useMemo(
    () =>
      formsSummary.filter(({ form_status, vendor }) =>
        isUnmapped(form_status, vendor)
      ).length,
    [formsSummary, isUnmapped]
  );

  const hasWebflowForms = useMemo(
    () =>
      !!formsSummary.filter(({ vendor }) => vendor === FORM_VENDORS.WEBFLOW)
        .length,
    [formsSummary]
  );

  const columnHelper = createColumnHelper<FormDetailsSummary>();

  const formColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <FormName
            name={info.getValue()}
            unmapped={isUnmapped(
              info.row.original.form_status,
              info.row.original.vendor
            )}
          />
        ),
        minSize: 300,
        header: "Form name",
      }),
      columnHelper.accessor("description", {
        cell: (info) => <Text noOfLines={1}>{info.getValue()}</Text>,
        minSize: 400,
        header: "Description",
      }),
      columnHelper.accessor("vendor", {
        cell: (info) => (
          <FormProviderWithLogo vendor={info.getValue() as FORM_VENDORS} />
        ),
        minSize: 150,
        header: "Form provider",
      }),
      columnHelper.display({
        cell: (info) => {
          const data = info.row.original.submissions;
          return (
            <WrapperWithSkeleton
              loading={isLoading(info.row.original.submissionStatsLoading)}
            >
              <Text>{data ? data : "-"}</Text>
            </WrapperWithSkeleton>
          );
        },
        minSize: 150,
        header: "Submissions",
      }),
      columnHelper.accessor("created_at", {
        cell: (info) => <FormatDate date={info.getValue()} />,
        minSize: 100,
        header: "Date added",
      }),
      columnHelper.accessor("form_status", {
        cell: (info) => (
          <WrapperWithSkeleton loading={isLoading(isFormStatusLoading)}>
            <FormStatus
              status={
                (vendorStatus &&
                vendorStatus[info.row.original.vendor] === STATUS.DISABLED
                  ? FORM_STATUS.DISCONNECTED
                  : info.getValue()) as FORM_STATUS
              }
            />
          </WrapperWithSkeleton>
        ),
        header: "Mapping status",
        minSize: 100,
      }),
    ],
    [columnHelper, isFormStatusLoading, isUnmapped, vendorStatus]
  );

  function CurrentFormState() {
    return !!connectedFormProviders.length ? (
      <FormsAwaitingState />
    ) : (
      <FormsEmptyState onConnectProvider={onOpen} />
    );
  }

  const isInitEmpty = !(formsList.totalPageCount !== 0 || isFiltersApplied);

  return (
    <>
      <CommonListHeader
        heading="Forms"
        createButtonProps={{
          name: "add-provider",
          onClick: onOpen,
          isLoading: false,
          text: "View providers",
        }}
        searchInputProps={{
          placeholder: "Search forms",
          name: "search-forms",
          onSearch: handleSearchChange,
          defaultValue: searchKeyword,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.FORMS,
          hidden: isInitEmpty,
        }}
      />
      <AlertUnmappedForms count={totalUnmappedForms} />
      {hasWebflowForms && <AlertWebflowForms />}

      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable
          height={`calc(100% - ${hasWebflowForms ? "75px" : "25px"} - ${
            !!totalUnmappedForms ? "75px" : "25px"
          })`}
          reduceHeightBy={getHeightOfFilter(filters)}
        >
          <DataTable
            fetchingList={fetchingList}
            changingPage={formsList.changingPage}
            list={formsList.list}
            columns={formColumns}
            totalPageCount={formsList.totalPageCount}
            totalPageSize={formsList.pageSize}
            currentPage={formsList.currentPageNo}
            onRowClick={(row: FormDetailsSummary) =>
              navigate(`${urls.form}/${row.form_id}`)
            }
            setPage={handlePageChange}
            emptyMsg={`No forms found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps(
                { to: urls.form, editParam: "form_id" },
                data
              )
            }
          />
        </LayoutWithTable>
      ) : (
        <CurrentFormState />
      )}
      <SelectFormProvider
        isOpen={isOpen}
        onClose={onClose}
        connectedProviders={connectedFormProviders}
        refreshFormsList={() => handlePageChange(1)}
      />
    </>
  );
}
