import {
  Alert,
  VStack,
  HStack,
  Icon,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
  Box,
} from "@chakra-ui/react";

const WEBFLOW_BANNER_DEADLINE = new Date("2025-01-15T00:00:00.000Z");

export const IS_BEFORE_DEADLINE =
  new Date().getTime() < WEBFLOW_BANNER_DEADLINE.getTime();

/**
 *
 * @param condensedView - If true, the banner will be styled to be fit in sidebars and tight spaces
 * @returns
 */
export default function WebflowVersionDeprecationBanner({
  condensedView = false,
}: {
  condensedView?: boolean;
}) {
  return IS_BEFORE_DEADLINE ? (
    <Alert bg="grayV2.100" rounded="md" w="100%">
      <VStack alignItems="flex-start" spacing="0" w="100%">
        <HStack justifyContent="center" spacing="1">
          <Icon as={AlertIcon} fontSize="xs" color="blue.700" h="24px" m="0" />
          <AlertTitle fontSize="xs" color="text.50">
            Note
          </AlertTitle>
        </HStack>
        <Box
          sx={
            condensedView
              ? {}
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  w: "100%",
                }
          }
          color="text.50"
          fontSize="xs"
        >
          <AlertDescription mr="2" lineHeight="4">
            Please configure webhook V2 API on Webflow, as V1 API will be
            deprecated on January 1, 2025.
          </AlertDescription>
          <Link
            href="https://developers.webflow.com/data/docs/webflow-v1-api-deprecation-notice"
            target={"_blank"}
            fontWeight="semibold"
            minWidth="70px"
          >
            Learn more
          </Link>
        </Box>
      </VStack>
    </Alert>
  ) : (
    <></>
  );
}
