import {
  Box,
  Divider,
  HStack,
  Link,
  SlideFade,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  addDays,
  endOfToday,
  formatISO,
  isAfter,
  startOfDay,
  subDays,
} from "date-fns";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import { FaRedo, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  convertToString,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import {
  SalesforceConnection,
  SalesforceSyncError,
  SALESFORCE_SYNC_ERROR_TYPE,
} from "../../../../../common/types/salesforceLegacyService";
import { DataTable } from "../../../../../components/data-table/DataTable";
import DateTimeRangeFilter, {
  FormatDate,
} from "../../../../../components/DateTimeRangeFilter";
import IButton from "../../../../../components/IButton";
import IconWithTooltip from "../../../../../components/IconWithTooltip";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import TableEntryText from "../../../../../components/TableEntryText";
import { useAppDispatch } from "../../../../../store";
import SubHeader from "../../components/SubHeader";
import {
  archiveSalesforceSyncError,
  getSalesforceCampaignSyncErrors,
  listSalesforceSyncErrors,
  listSalesforceSyncErrorsTypes,
  resyncSalesforceSyncError,
  selectSalesforceLegacy,
} from "../salesforceLegacySlice";
import SalesforceLegacyAffectedRecordsModal from "./SalesforceLegacyAffectedRecordsModal";
import { SfCampaignSyncError } from "../../../../../common/types/salesforce";

const SalesforceLegacySyncErrors = forwardRef(
  (
    {
      connectionDetails,
    }: {
      connectionDetails: SalesforceConnection;
    },
    ref
  ) => {
    const pageSize = 10;
    const dispatch = useAppDispatch();
    const {
      syncErrors,
      sfErrorTypes,
      fetchingErrorList,
      campaignSyncErrors,
      resyncingSalesforceSyncError,
      archivingSalesforceSyncError,
    } = useSelector(selectSalesforceLegacy);

    const startDate = startOfDay(subDays(new Date(), 7));
    const endDate = endOfToday();

    const [[startTimeSfCampaign, endTimeSfCampaign], setDateRangeSfCampaign] =
      useState([startDate, endDate]);
    const [[startTime, endTime], setDateRange] = useState([startDate, endDate]);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState<SalesforceSyncError[]>([]);

    const connectionId = connectionDetails.connection_id;

    const lastSearchedDate = useRef({ startTime, endTime });
    const [selectedRow, setSelectedRow] = useState<SalesforceSyncError | null>(
      null
    );

    const {
      isOpen: isOpenAffectedRecordsModal,
      onOpen: onOpenAffectedRecordsModal,
      onClose: onCloseAffectedRecordsModal,
    } = useDisclosure();

    const {
      isOpen: isOpenArchiveResyncTab,
      onOpen: onOpenArchiveResyncTab,
      onClose: onCloseArchiveResyncTab,
    } = useDisclosure();

    function dateFilterHandlerSfCampaign(startTime: Date, endTime: Date) {
      dispatch(
        getSalesforceCampaignSyncErrors({
          startTimestamp: Date.parse(startTime + ""),
          endTimestamp: Date.parse(endTime + ""),
        })
      );
    }

    function dateFilterHandler(startTime: Date, endTime: Date) {
      if (connectionId && sfErrorTypes.data) {
        sfErrorTypes.data.forEach((key, value) => {
          dispatch(
            listSalesforceSyncErrors({
              connectionId,
              errorType: key.name,
              startTime: formatISO(startTime),
              endTime: formatISO(endTime),
              pageSize: pageSize,
              pageNumber: 1,
            })
          );
        });
        lastSearchedDate.current = { startTime, endTime };
      }
    }

    useImperativeHandle(ref, () => ({
      filterWithDate(date: Date) {
        const startDate = date;
        const endDate = new Date();
        dateFilterHandler(startDate, endDate);
        setDateRange([startDate, endDate]);
      },
    }));

    useEffect(() => {
      dispatch(listSalesforceSyncErrorsTypes());
    }, [dispatch]);

    function formatText(value: string | undefined) {
      return <TableEntryText>{value ?? "-"}</TableEntryText>;
    }

    function formatCount(value: number) {
      return `${convertToString(value)} record${value > 1 ? "s" : ""}`;
    }

    const handleOpenAffectedRecordsModal = useCallback(
      (row: SalesforceSyncError) => {
        setSelectedRow(row);
        onOpenAffectedRecordsModal();
      },
      [onOpenAffectedRecordsModal]
    );

    function closeAffectedRecordsModal() {
      setSelectedRow(null);
      onCloseAffectedRecordsModal();
    }

    const sfCampaignColumnHelper = createColumnHelper<SfCampaignSyncError>();

    const sfCampaignColumns = useMemo(
      () => [
        sfCampaignColumnHelper.accessor("error", {
          header: "Error Class",
          size: 450,
          cell: (value) => formatText(value.getValue()),
        }),
        sfCampaignColumnHelper.accessor("count", {
          header: "Affected Records",
          size: 120,
          cell: (info) => formatCount(info.getValue()),
          meta: {
            isNumeric: true,
          },
        }),
        sfCampaignColumnHelper.accessor("salesforceCampaignId", {
          header: "Salesforce Campaign ID",
          size: 100,
          cell: (value) => formatText(value.getValue()),
        }),
        sfCampaignColumnHelper.accessor("firstDetectedTimestamp", {
          header: "First Detected",
          size: 120,
          cell: (info) => (
            <FormatDate
              date={new Date(info.getValue()).toISOString()}
              showTime
              splitLines
            />
          ),
        }),
        sfCampaignColumnHelper.accessor("lastDetectedTimestamp", {
          header: "Last Detected",
          size: 120,
          cell: (info) => (
            <FormatDate
              date={new Date(info.getValue()).toISOString()}
              showTime
              splitLines
            />
          ),
        }),
      ],
      [sfCampaignColumnHelper]
    );

    const columnHelper = createColumnHelper<SalesforceSyncError>();

    const columns = useMemo(
      () => [
        columnHelper.accessor("error_class", {
          header: "Error Class",
          size: 500,
          cell: (value) => formatText(value.getValue()),
        }),
        columnHelper.accessor("count", {
          header: "Affected Records",
          size: 100,
          cell: (info) => (
            <Link
              onClick={() => handleOpenAffectedRecordsModal(info.row.original)}
            >
              <Text color="blue.600" as="u">
                {formatCount(info.getValue())}
              </Text>
            </Link>
          ),
          meta: {
            isNumeric: true,
          },
        }),
        columnHelper.accessor("first_occurred", {
          header: "First Detected",
          size: 100,
          cell: (info) => (
            <FormatDate date={info.getValue()} showTime splitLines />
          ),
        }),
        columnHelper.accessor("last_occurred", {
          header: "Last Detected",
          size: 100,
          cell: (info) => (
            <FormatDate date={info.getValue()} showTime splitLines />
          ),
        }),
      ],
      [columnHelper, handleOpenAffectedRecordsModal]
    );

    function switchTabs(value: number) {
      setTabIndex(value);
    }

    const isResyncing: boolean = useMemo(
      () => isLoading(resyncingSalesforceSyncError),
      [resyncingSalesforceSyncError]
    );

    const isArchiving = useMemo(
      () => isLoading(archivingSalesforceSyncError),
      [archivingSalesforceSyncError]
    );

    useEffect(() => {
      if (sfErrorTypes.data) {
        sfErrorTypes.data.map(async (key, value) => {
          dispatch(
            listSalesforceSyncErrors({
              connectionId,
              errorType: sfErrorTypes.data[value].name,
              startTime: formatISO(lastSearchedDate.current.startTime),
              endTime: formatISO(lastSearchedDate.current.endTime),
              pageSize: pageSize,
              pageNumber: 1,
            })
          );
        });
      }
    }, [sfErrorTypes.data, connectionId, dispatch, isResyncing, isArchiving]);

    useEffect(() => {
      const startTimestamp = Date.parse(startDate + "");
      const endTimestamp = Date.parse(endDate + "");
      dispatch(
        getSalesforceCampaignSyncErrors({ startTimestamp, endTimestamp })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
      if (isArchiving) {
        setSelectedRows([]);
      }
    }, [isArchiving]);

    useEffect(() => {
      if (isResyncing) {
        setSelectedRows([]);
      }
    }, [isResyncing]);

    const [requiredErrorType, setRequiredErrorType] = useState<
      string | undefined
    >("");
    const [requiredErrorDisplay, setRequiredErrorDisplay] =
      useState<SALESFORCE_SYNC_ERROR_TYPE>(SALESFORCE_SYNC_ERROR_TYPE.NONE);

    useEffect(() => {
      if (sfErrorTypes.data[tabIndex]) {
        setRequiredErrorType(sfErrorTypes.data[tabIndex].name);
        setRequiredErrorDisplay(sfErrorTypes.data[tabIndex].display);
      }
    }, [sfErrorTypes.data, tabIndex]);

    useEffect(() => {
      if (selectedRows.length === 0) {
        onCloseArchiveResyncTab();
      } else {
        onOpenArchiveResyncTab();
      }
    }, [selectedRows, onCloseArchiveResyncTab, onOpenArchiveResyncTab]);

    function wrapErrorData() {
      const errorClassList = selectedRows.map((value) => {
        return value.error_class;
      });
      const wrappedData = {
        errorClassList,
        startTime: formatISO(startTime),
        endTime: formatISO(endTime),
      };
      return wrappedData;
    }

    function handleResyncError() {
      const resyncData = wrapErrorData();
      dispatch(resyncSalesforceSyncError(resyncData));
    }

    function handleArchiveError() {
      const archiveData = wrapErrorData();
      dispatch(archiveSalesforceSyncError(archiveData));
    }

    function ResyncArchiveTab() {
      return (
        <SlideFade in={isOpenArchiveResyncTab}>
          <HStack spacing="20px" bgColor="gray.100" py="2">
            <Box pl="20px">
              <Text fontSize="sm">
                {selectedRows.length === 0
                  ? null
                  : `${selectedRows.length} selected`}
              </Text>
            </Box>
            <IButton
              size="sm"
              aria-label="Resync"
              disabled={selectedRows.length === 0}
              onClick={handleResyncError}
              isLoading={isLoading(resyncingSalesforceSyncError)}
              leftIcon={<FaRedo fontSize="sm" />}
            >
              Resync
            </IButton>
            <IButton
              size="sm"
              aria-label="Archive"
              disabled={selectedRows.length === 0}
              onClick={handleArchiveError}
              isLoading={isLoading(archivingSalesforceSyncError)}
              leftIcon={<FaTrash fontSize="sm" />}
            >
              Archive
            </IButton>
          </HStack>
        </SlideFade>
      );
    }

    function changePage(pageNo: number, requiredErrorType: string) {
      listSalesforceSyncErrors({
        connectionId,
        errorType: requiredErrorType as string,
        startTime: formatISO(lastSearchedDate.current.startTime),
        endTime: formatISO(lastSearchedDate.current.endTime),
        pageSize: pageSize,
        pageNumber: pageNo,
      });
    }
    function onDateChangeSfCampaign(start: Date, end: Date) {
      const thirtyDaysFromStartDate = addDays(start, 30);
      if (isAfter(end, thirtyDaysFromStartDate)) {
        setDateRangeSfCampaign([start, thirtyDaysFromStartDate]);
      } else {
        setDateRangeSfCampaign([start, end]);
      }
    }

    return (
      <Box p="5" bg="white" rounded="md">
        <SubHeader title="Sync errors">
          {tabIndex === sfErrorTypes.data.length ? (
            <HStack>
              <IconWithTooltip label="View all errors in a 30 day window" />
              <DateTimeRangeFilter
                startDate={startTimeSfCampaign}
                endDate={endTimeSfCampaign}
                onDateChange={onDateChangeSfCampaign}
                maxEndDate={addDays(startTimeSfCampaign, 30)}
                dateFilterHandler={() =>
                  dateFilterHandlerSfCampaign(
                    startTimeSfCampaign,
                    endTimeSfCampaign
                  )
                }
              />
            </HStack>
          ) : (
            <DateTimeRangeFilter
              startDate={startTime}
              endDate={endTime}
              onDateChange={(startTime, endTime) =>
                setDateRange([startTime, endTime])
              }
              dateFilterHandler={() => dateFilterHandler(startTime, endTime)}
            />
          )}
        </SubHeader>
        <Tabs p={0} tabIndex={tabIndex}>
          <TabList borderBottom={1}>
            {sfErrorTypes.data.map((key, value) => {
              return (
                <Tab fontSize="sm" onClick={() => switchTabs(value)}>
                  {key.display} ({syncErrors[key.name]?.count ?? 0})
                </Tab>
              );
            })}
            <Tab
              fontSize="sm"
              onClick={() => switchTabs(sfErrorTypes.data.length)}
            >
              Campaign Sync ({campaignSyncErrors?.length ?? 0})
            </Tab>
          </TabList>
          <Divider pt="3px" />
          <SpinnerContainer loading={isLoading(fetchingErrorList)}>
            {tabIndex === sfErrorTypes.data.length ? (
              <>
                <Box h="46px" visibility="hidden">
                  ReSync Archive Tab Dummy
                </Box>
                <DataTable
                  fetchingList={isLoading(fetchingErrorList)}
                  changingPage={false}
                  list={campaignSyncErrors}
                  totalPageSize={campaignSyncErrors?.length ?? 0}
                  setPage={() => {}}
                  totalPageCount={1}
                  currentPage={1}
                  columns={sfCampaignColumns}
                  emptyMsg="No sync errors found"
                />
              </>
            ) : (
              requiredErrorType &&
              syncErrors[requiredErrorType] && (
                <>
                  <ResyncArchiveTab />
                  <DataTable
                    fetchingList={isLoading(fetchingErrorList)}
                    changingPage={false}
                    list={syncErrors[requiredErrorType].list}
                    totalPageSize={syncErrors[requiredErrorType].pageSize}
                    setPage={(pageNo: number) =>
                      changePage(pageNo, requiredErrorType)
                    }
                    totalPageCount={
                      syncErrors[requiredErrorType].totalPageCount
                    }
                    currentPage={
                      syncErrors[requiredErrorType].currentPageNo ?? 1
                    }
                    columns={columns}
                    emptyMsg="No sync errors found"
                    onSelectionChange={setSelectedRows}
                  />
                </>
              )
            )}
          </SpinnerContainer>
          <SalesforceLegacyAffectedRecordsModal
            isOpen={isOpenAffectedRecordsModal}
            onClose={closeAffectedRecordsModal}
            errorType={requiredErrorDisplay ?? SALESFORCE_SYNC_ERROR_TYPE.NONE}
            data={selectedRow}
          />
        </Tabs>
      </Box>
    );
  }
);

export default SalesforceLegacySyncErrors;
