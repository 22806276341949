import { HStack, Icon, Text, Tooltip } from "@chakra-ui/react";
import { IconType } from "react-icons";

export default function StatsWithIconAndCount({
  icon,
  text,
  tooltip,
}: {
  icon: IconType;
  text: string | number;
  tooltip?: string;
}) {
  return (
    <Tooltip label={tooltip ?? text}>
      <HStack width="100%" p="2" justifyContent="center">
        <Icon as={icon} fontSize="lg" color="brandBlue.500" />
        <Text fontWeight="600" fontSize="sm" color="gray.600">
          {text}
        </Text>
      </HStack>
    </Tooltip>
  );
}
