import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Img,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaPencilAlt, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  getTableRowLinkProps,
  isFulfilled,
  isSuccess,
} from "../../../../../../../common/helper/commonHelper";
import { PaginationTypeWithLoadingState } from "../../../../../../../common/types/common";
import {
  ConnectionListItem,
  SOURCES,
} from "../../../../../../../common/types/unifiedMapping";
import IButton, { BUTTON } from "../../../../../../../components/IButton";
import { useAppDispatch } from "../../../../../../../store";
import {
  clearConnectionDetailsData,
  getAllConnections,
  selectConnection,
  setAllConnectionsPageNo,
  wizardSetStep,
} from "../../../../connectionSlice";
import { ReactComponent as DwCallout } from "../../../../../../../common/img/connections.svg";
import AddConnectionModal from "../../../../connectionList/components/AddConnectionModal";
import ConnectionDetailsModal from "../../../../connectionList/components/ConnectionDetailsModal";
import SalesforceConnectionModal from "../../../../connectionList/components/SalesforceConnectionModal";
import { selectConstants } from "../../../../../../../common/slices/constantsSlice";
import { useNavigate } from "react-router-dom";
import { matchPath } from "react-router";
import urls from "../../../../../../../urls";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DATA_CONNECTION_SERVICES_LIST } from "../../../../../../../common/constants/connection";
import { capitalize, isEmpty } from "lodash";
import { DataTable } from "../../../../../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import SegmentConnectionModal from "../../../../segment/components/SegmentConnectionModal";
import { redirectToSalesforceLoginPage } from "../../../../../../../common/helper/salesforceLegacyServiceHelper";
import { FormatDate } from "../../../../../../../components/DateTimeRangeFilter";
import AddFormProvider from "../../../../../form/components/AddFormProvider";
import { FORM_VENDORS } from "../../../../../../../common/types/form";
import { isFormProvider } from "../../../../../form/helper";
import { integrateFormsProvider } from "../../../../../form/formSlice";
import {
  selectSalesforceLegacy,
  createSalesforceConnection,
  getSalesforceLoginUrl,
} from "../../../../salesforceLegacyService/salesforceLegacySlice";
import { initSalesforceConnectionApi } from "../../../../../../../common/api/salesforceIntegrations/salesforceConnection";
import { InitSalesforceConReqType } from "../../../../../../../common/types/salesforce";
import {
  initSalesforceConnection,
  selectSalesforce,
} from "../../../../salesforce/salesforceSlice";
import { redirectToSfLoginPageWithoutLocalStorage } from "../../../../../../../common/helper/salesforceHelper";
import { selectFeatureFlag } from "../../../../../../../common/slices/featureFlagSlice";
import { CONTACTS_INGESTION_SERVICE } from "../../../../../../../common/types/featureFlag";
import SalesforceFlinkConnectionModal from "../../../../salesforce/components/SalesforceFlinkConnectionModal";
import { isLoading } from "../../../../../../../common/helper/commonHelper";

const STEP1 = "step1";
const STEP2 = "step2";

const STATUS_COLORS: Record<string, string> = {
  active: "green",
  paused: "yellow",
};

const APPEND_NO_ID_IN_ROUTE = [
  SOURCES.SEGMENT,
  SOURCES.FORMSTACK,
  SOURCES.WEBFLOW,
];

const SERVICES_TO_CONNECT_ONLY_ONCE = [
  SOURCES.SEGMENT,
  SOURCES.SALESFORCE,
  ...Object.values(FORM_VENDORS),
];

enum CONNECTION_MODALS {
  NEW_CONNECTION,
  CONNECTION_DETAILS,
  SALESFORCE,
  SEGMENT,
  FORM_PROVIDER,
}

function CalloutAction({ buttonAction }: { buttonAction: () => void }) {
  return (
    <Box
      minH="calc(100vh - 152px)"
      display="flex"
      alignItems="center"
      flexDir="column"
      mt="20px"
    >
      <VStack spacing={6}>
        <DwCallout />
        <Text fontWeight={"bold"} fontSize="xl">
          Let's start adding your first connection
        </Text>
        <Text fontSize="sm" fontWeight="semibold" textAlign="center">
          Connect from many easy to setup platforms. <br /> Let's start by
          adding your first connection
        </Text>
        <Box>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={buttonAction}
            mt={10}
            name="add-new-connection"
          >
            Add your first connection
          </IButton>
        </Box>
      </VStack>
    </Box>
  );
}

export default function Connections({
  connectionList,
}: {
  connectionList: PaginationTypeWithLoadingState<ConnectionListItem>;
}) {
  const {
    authedConstants: { warehouse_types },
  } = useSelector(selectConstants);
  const { selectedService, connectionDetails } = useSelector(selectConnection);
  const { creatingSalesforceConnection, connection, loginUrl } = useSelector(
    selectSalesforceLegacy
  );
  const {
    contactIngestionEtl: { data: etl },
  } = useSelector(selectFeatureFlag);
  const {
    oAuthUrl: { loading: oAuthUrlLoading },
  } = useSelector(selectSalesforce);

  const dispatch = useAppDispatch();

  const [connectionModal, setConnectionModal] =
    useState<CONNECTION_MODALS | null>(null);
  const [editUrl, setEditUrl] = useState(urls.unifiedMapping);

  function onOpenConnectionModal(modal: CONNECTION_MODALS) {
    setConnectionModal(modal);
  }

  function onCloseConnectionModal() {
    setConnectionModal(null);
  }

  function isOpenConnectionModal(modal: CONNECTION_MODALS) {
    return modal === connectionModal;
  }

  let navigate = useNavigate();

  const getRedirectUrl = useCallback((source: SOURCES) => {
    switch (source) {
      case SOURCES.SALESFORCE:
        return urls.salesforceRoute;
      case SOURCES.SEGMENT:
        return urls.segment;
      case SOURCES.SNOWFLAKE:
      case SOURCES.REDSHIFT:
      case SOURCES.BIGQUERY:
        return urls.unifiedMapping;
      case SOURCES.WEBFLOW:
      case SOURCES.FORMSTACK:
        return `${urls.formProvider}/${source}`;
    }
  }, []);

  const redirectToEditPage = useCallback(
    (id: string, source: SOURCES) => {
      const noId = APPEND_NO_ID_IN_ROUTE.includes(source);
      navigate(`${getRedirectUrl(source)}${noId ? "" : `/${id}`}`);
    },
    [navigate, getRedirectUrl]
  );

  const onRightClick = useCallback(
    (source: SOURCES) => {
      const url = getRedirectUrl(source);
      if (url && url !== editUrl) {
        setEditUrl(url);
      }
    },
    [editUrl, getRedirectUrl]
  );

  const linksToEdit: { to: string; editPara?: ConnectionListItem } =
    useMemo(() => {
      return {
        to: editUrl,
        editParam:
          editUrl === urls.segment ||
          isEmpty(matchPath(editUrl, urls.formIntegration))
            ? undefined
            : "connection_id",
      };
    }, [editUrl]);

  const columnHelper = createColumnHelper<ConnectionListItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => {
          return (
            <Badge
              fontSize="xs"
              colorScheme={STATUS_COLORS[info.getValue()]}
              variant="solid"
            >
              {info.getValue()}
            </Badge>
          );
        },
      }),
      columnHelper.accessor("name", {
        header: "Connection Name",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("source", {
        header: "Source",
        cell: (info) => {
          const service = DATA_CONNECTION_SERVICES_LIST.find(
            (x) => x.id === info.getValue()
          );
          return (
            <HStack>
              <Img h="12px" src={service?.imgSrc} />
              <Text>{service?.label}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor("connected_tables", {
        header: "Connected Tables",
        cell: (info) => (info.getValue() ? info.getValue().join(",") : "-"),
      }),
      columnHelper.accessor("sync_interval", {
        header: "Sync Interval",
        cell: (info) => (info.getValue() ? capitalize(info.getValue()!) : "-"),
      }),
      columnHelper.accessor("last_sync_time", {
        header: "Last Synced",
        cell: (info) => <FormatDate date={info.getValue()} showTime />,
      }),
      columnHelper.display({
        header: "",
        id: "actions",
        cell: (info) => {
          return (
            <IButton
              variant="link"
              leftIcon={<FaPencilAlt fontSize={10} />}
              size="sm"
              onClick={() =>
                redirectToEditPage(
                  info.row.original.connection_id,
                  info.row.original.source
                )
              }
            >
              Edit
            </IButton>
          );
        },
      }),
    ],
    [columnHelper, redirectToEditPage]
  );

  const next = (modalKey: string, service?: string) => {
    onCloseConnectionModal();
    switch (modalKey) {
      case "step1":
        if (service === SOURCES.SALESFORCE) {
          onOpenConnectionModal(CONNECTION_MODALS.SALESFORCE);
        } else if (service === SOURCES.SEGMENT) {
          onOpenConnectionModal(CONNECTION_MODALS.SEGMENT);
        } else if (service && isFormProvider(service)) {
          onOpenConnectionModal(CONNECTION_MODALS.FORM_PROVIDER);
          dispatch(integrateFormsProvider(service as FORM_VENDORS));
        } else {
          // resets the step flow to step 1 as redshift begins flow from step 1,
          // while bigquery and snowflake begins from step 2
          // refer: ConnectionDetailsModal.tsx
          dispatch(wizardSetStep(1));
          onOpenConnectionModal(CONNECTION_MODALS.CONNECTION_DETAILS);
        }
        break;
      case "step2":
        if (connectionDetails)
          navigate(`${urls.connectionConfiguration}/${connectionDetails.id}`);
        break;
    }
  };

  function salesforceLoginLegacy(name: string) {
    dispatch(createSalesforceConnection(name));
  }

  async function salesforceLoginFlink(data: InitSalesforceConReqType) {
    const initSf = await dispatch(initSalesforceConnection(data));
    if (isFulfilled(initSf.meta.requestStatus)) {
      const { data: oathUrlData } = initSf.payload as Awaited<
        ReturnType<typeof initSalesforceConnectionApi>
      >;
      if (oathUrlData) {
        redirectToSfLoginPageWithoutLocalStorage(oathUrlData.oauthUrl);
        onCloseConnectionModal();
      }
    }
  }

  useEffect(() => {
    if (isSuccess(creatingSalesforceConnection)) {
      dispatch(getSalesforceLoginUrl());
    }
  }, [dispatch, creatingSalesforceConnection]);

  useEffect(() => {
    if (loginUrl.data && connection?.connection_id) {
      redirectToSalesforceLoginPage(loginUrl.data, connection.connection_id);
    }
  }, [dispatch, loginUrl, connection?.connection_id]);

  const servicesToDisableIfExists = useCallback(() => {
    return connectionList && connectionList.list
      ? connectionList.list
          .filter((x) => SERVICES_TO_CONNECT_ONLY_ONCE.includes(x.source))
          ?.map((x) => x.source)
      : [];
  }, [connectionList]);

  return (
    <Box
      bg="white"
      w="100%"
      rounded="md"
      p="5"
      borderColor="grayV2.200"
      borderWidth="1px"
    >
      <Heading fontSize="lg" mb="5">
        Connections
      </Heading>
      {connectionList.totalPageCount === 0 ? (
        <CalloutAction
          buttonAction={() =>
            onOpenConnectionModal(CONNECTION_MODALS.NEW_CONNECTION)
          }
        />
      ) : (
        <DataTable
          fetchingList={isLoading(connectionList.loadingList)}
          changingPage={connectionList.changingPage}
          list={connectionList.list}
          setPage={(pageNo: number) =>
            dispatch(setAllConnectionsPageNo(pageNo))
          }
          totalPageCount={connectionList.totalPageCount}
          currentPage={connectionList.currentPageNo}
          totalPageSize={connectionList.pageSize}
          columns={columns}
          emptyMsg="No connections found"
          onRowClick={(row) =>
            redirectToEditPage(row.connection_id, row.source)
          }
          onRowRightClick={(row) => onRightClick(row.source)}
          getTableRowLinkProps={(data) =>
            getTableRowLinkProps(linksToEdit, data)
          }
        />
      )}
      <Flex m="3">
        <IButton
          leftIcon={<FaPlus />}
          variant="link"
          onClick={() => {
            dispatch(clearConnectionDetailsData());
            onOpenConnectionModal(CONNECTION_MODALS.NEW_CONNECTION);
          }}
        >
          Add New Connection
        </IButton>
      </Flex>

      <AddConnectionModal
        disabledServices={servicesToDisableIfExists()}
        isOpen={isOpenConnectionModal(CONNECTION_MODALS.NEW_CONNECTION)}
        onClose={onCloseConnectionModal}
        goToNext={(e) => next(STEP1, e)}
      />

      {warehouse_types && warehouse_types[selectedService] && (
        <ConnectionDetailsModal
          serviceDetails={warehouse_types[selectedService]}
          isOpen={isOpenConnectionModal(CONNECTION_MODALS.CONNECTION_DETAILS)}
          onClose={onCloseConnectionModal}
          goToNext={() => next(STEP2)}
          goToStart={() => {
            onOpenConnectionModal(CONNECTION_MODALS.NEW_CONNECTION);
            dispatch(wizardSetStep(0));
          }}
        />
      )}

      {etl === CONTACTS_INGESTION_SERVICE.LEGACY && (
        <SalesforceConnectionModal
          isOpen={isOpenConnectionModal(CONNECTION_MODALS.SALESFORCE)}
          onClose={onCloseConnectionModal}
          submit={salesforceLoginLegacy}
          isInitializingConnection={
            isLoading(creatingSalesforceConnection) ||
            isLoading(loginUrl.loading)
          }
        />
      )}
      {etl === CONTACTS_INGESTION_SERVICE.FLINK && (
        <SalesforceFlinkConnectionModal
          isOpen={isOpenConnectionModal(CONNECTION_MODALS.SALESFORCE)}
          onClose={onCloseConnectionModal}
          submit={salesforceLoginFlink}
          isInitializingConnection={isLoading(oAuthUrlLoading)}
        />
      )}
      <SegmentConnectionModal
        isOpen={isOpenConnectionModal(CONNECTION_MODALS.SEGMENT)}
        onClose={onCloseConnectionModal}
      />
      {isFormProvider(selectedService) && (
        <AddFormProvider
          vendor={selectedService as FORM_VENDORS}
          isOpen={isOpenConnectionModal(CONNECTION_MODALS.FORM_PROVIDER)}
          onClose={onCloseConnectionModal}
          onProceed={() => dispatch(getAllConnections())}
        />
      )}
    </Box>
  );
}
