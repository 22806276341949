import {
  Box,
  ButtonProps,
  HStack,
  Icon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FORM_VENDORS } from "../../../../common/types/form";
import IButton, { BUTTON } from "../../../../components/IButton";
import MemoizedCommonDrawer from "../../campaign/components/CommonDrawer";
import CopyText from "./CopyText";
import { FORM_PROVIDER_DETAILS } from "../helper";
import { useSelector } from "react-redux";
import {
  listAllForms,
  resetformProviderDetails,
  selectForm,
} from "../formSlice";
import { isFulfilled, isLoading } from "../../../../common/helper/commonHelper";
import { useAppDispatch } from "../../../../store";
import { listAllFormsApi } from "../../../../common/api/integrations/form";
import WebflowVersionDeprecationBanner from "./WebflowVersionDeprecationBanner";

const WAIT_TIME_FORMS = 20000;

function ProviderIntegrationDetails({
  vendor,
  onCancel,
  onProceed,
}: {
  vendor: FORM_VENDORS;
  onCancel: () => void;
  onProceed: () => void;
}) {
  const providerName = FORM_PROVIDER_DETAILS[vendor]?.name ?? "";

  const {
    formProviderDetails: {
      data: providerDetails,
      loading: providerDetailsLoading,
    },
  } = useSelector(selectForm);

  const buttonProps: ButtonProps = {
    isLoading: isLoading(providerDetailsLoading),
    isDisabled:
      !providerDetails.webhook_url ||
      (vendor === FORM_VENDORS.FORMSTACK && !providerDetails.shared_key),
  };

  return (
    <>
      <Text fontWeight="500" pt={2}>
        Add {providerName.toLowerCase()}
      </Text>
      <CopyText
        textType="webhook"
        text={providerDetails.webhook_url}
        buttonProps={buttonProps}
      />
      <Text color="gray.600" fontSize="xs" my={2}>
        {providerName} will start sending us form submissions through this
        webhook. Once you update the webhook, hit{" "}
        <Text as="span" fontWeight="semibold">
          Proceed
        </Text>{" "}
        and test your webhook through a form submission.
      </Text>
      {vendor === FORM_VENDORS.FORMSTACK && (
        <Box mt={3} w="100%">
          <Text fontWeight="500" pt={2}>
            Shared secret key
          </Text>
          <CopyText
            text={providerDetails.shared_key ?? ""}
            textType="text"
            buttonProps={buttonProps}
          />
          <Text color="gray.600" fontSize="xs" my={2}>
            Formstack ensures the that the form data shared is secure by using a
            shared secret key.
          </Text>
        </Box>
      )}
      {vendor === FORM_VENDORS.WEBFLOW && (
        <Box my={2} w="100%">
          <WebflowVersionDeprecationBanner condensedView />
        </Box>
      )}

      <IButton onClick={onProceed} {...buttonProps} float="right">
        proceed
      </IButton>
    </>
  );
}

function AwaitFormSubmission({
  providerName,
  awaitingForms,
  onProceed,
}: {
  providerName: string;
  awaitingForms: boolean;
  onProceed: () => void;
}) {
  return (
    <>
      <HStack alignItems="baseline">
        {awaitingForms ? (
          <Spinner size="xs" speed="0.6s" />
        ) : (
          <Icon as={FaCheck} fontSize="16px" color="green.400" pt={1} />
        )}
        <Text fontWeight="medium" fontSize="sm" pt={3}>
          Submission test
        </Text>
      </HStack>
      <Text fontSize="xs" my={4}>
        {`Submit a form data or wait for a form submission to get reflected
          here. Once we get a form submission, we can verify that the ${providerName} webhook setup.`}
      </Text>
      {awaitingForms ? (
        <IButton
          my={3}
          float="right"
          variant={BUTTON.SECONDARY}
          onClick={onProceed}
        >
          skip
        </IButton>
      ) : (
        <IButton my={3} float="right" onClick={onProceed}>
          done
        </IButton>
      )}
    </>
  );
}

export default function AddFormProvider({
  vendor,
  isOpen,
  onClose,
  onProceed,
}: {
  vendor: FORM_VENDORS;
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
}) {
  const providerName = FORM_PROVIDER_DETAILS[vendor].name;

  const [currentTab, setCurrentTab] = useState<0 | 1>(0);
  const [awaitingForms, setAwaitingForms] = useState(true);

  const dispatch = useAppDispatch();
  const date = useMemo(() => new Date(), []);

  useEffect(() => {
    if (isOpen) {
      setCurrentTab(0);
      setAwaitingForms(true);
    }
  }, [isOpen]);

  const waitForForms = useCallback(async () => {
    const { payload, meta } = await dispatch(listAllForms(vendor));
    const { forms } = payload as Awaited<ReturnType<typeof listAllFormsApi>>;
    const awaitForms =
      isFulfilled(meta.requestStatus) &&
      !!forms.records.length &&
      forms.records.some(
        ({ created_at }) => new Date(created_at).getTime() >= date.getTime()
      );
    setAwaitingForms(!awaitForms);
  }, [date, dispatch, vendor]);

  useEffect(() => {
    return () => {
      dispatch(resetformProviderDetails());
    };
  }, [dispatch, vendor]);

  useEffect(() => {
    if (currentTab && awaitingForms) {
      const intervalId = setInterval(() => {
        waitForForms();
      }, WAIT_TIME_FORMS);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [awaitingForms, currentTab, waitForForms]);

  function onCloseSubmission() {
    setAwaitingForms(false);
    onClose();
  }

  function onFormsSubmission() {
    onProceed();
    onCloseSubmission();
  }

  const currentTabContent = {
    0: (
      <ProviderIntegrationDetails
        vendor={vendor}
        onCancel={onClose}
        onProceed={() => setCurrentTab(1)}
      />
    ),
    1: (
      <AwaitFormSubmission
        providerName={providerName}
        awaitingForms={awaitingForms}
        onProceed={onFormsSubmission}
      />
    ),
  };

  return (
    <MemoizedCommonDrawer
      isOpen={isOpen}
      placement="right"
      onClose={onCloseSubmission}
      title={providerName}
      size="md"
    >
      {currentTabContent[currentTab]}
    </MemoizedCommonDrawer>
  );
}
