import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { useContactIngestionApiRetry } from "./commonHooks";
import { selectFeatureFlag } from "../slices/featureFlagSlice";
import { useEffect } from "react";
import { CONTACTS_INGESTION_SERVICE } from "../types/featureFlag";
import {
  getSalesforceConnectionDetails,
  getSalesforceConnectionList,
  selectSalesforceLegacy,
  getSalesforceObjects,
} from "../../pages/dashboard/connection/salesforceLegacyService/salesforceLegacySlice";
import {
  listAllSalesforceConnections,
  selectSalesforce,
  getSalesforceConnectionDetails as getSalesforceConnection,
  listAllSalesforceSyncObjectColumns,
} from "../../pages/dashboard/connection/salesforce/salesforceSlice";
import { transformSalesforceConnectionFromLegacyToNew } from "../helper/salesforceHelper";
import {
  SalesforceConnectionsSummary,
  SalesforceConnectionV2,
} from "../types/salesforce";
import { LOADING_STATES } from "../constants/common";
import { transformSfSyncObjColumnsFromNewToLegacyForUnifiedMapping } from "../helper/salesforceHelper";

export function useSalesforceConnection({
  connectionId,
}: {
  connectionId: string | null;
}): { data: SalesforceConnectionV2 | null; loading: LOADING_STATES } {
  const {
    contactIngestionEtl: { data: etl },
  } = useSelector(selectFeatureFlag);
  const { sfConnection, fetchingSfConnection } = useSelector(selectSalesforce);
  const { connection, fetchingConnection } = useSelector(
    selectSalesforceLegacy
  );

  const dispatch = useAppDispatch();
  useContactIngestionApiRetry();

  useEffect(() => {
    switch (etl) {
      case CONTACTS_INGESTION_SERVICE.LEGACY:
        if (connectionId && !connection) {
          dispatch(getSalesforceConnectionDetails(connectionId));
        }
        break;

      case CONTACTS_INGESTION_SERVICE.FLINK:
        if (connectionId && !sfConnection) {
          dispatch(getSalesforceConnection(connectionId));
        }
        break;
    }
  }, [connectionId, connection, dispatch, etl, sfConnection]);

  function getSfConnection() {
    switch (etl) {
      case CONTACTS_INGESTION_SERVICE.LEGACY:
        return {
          data: connection
            ? transformSalesforceConnectionFromLegacyToNew(connection)
            : null,
          loading: fetchingConnection,
        };
      case CONTACTS_INGESTION_SERVICE.FLINK:
        return { data: sfConnection, loading: fetchingSfConnection };
      default:
        return { data: null, loading: LOADING_STATES.INIT };
    }
  }
  return getSfConnection();
}

export function useSalesforceConnectionList(): {
  data: SalesforceConnectionV2[] | null;
  loading: LOADING_STATES;
} {
  const {
    contactIngestionEtl: { data: etl },
  } = useSelector(selectFeatureFlag);
  const {
    sfConnectionList: { list, loadingList },
  } = useSelector(selectSalesforce);
  const { connectionList: legacyList } = useSelector(selectSalesforceLegacy);

  const dispatch = useAppDispatch();
  useContactIngestionApiRetry();

  useEffect(() => {
    switch (etl) {
      case CONTACTS_INGESTION_SERVICE.LEGACY:
        dispatch(getSalesforceConnectionList());
        break;

      case CONTACTS_INGESTION_SERVICE.FLINK:
        dispatch(listAllSalesforceConnections());
        break;
    }
  }, [dispatch, etl]);

  function getSalesforceList() {
    switch (etl) {
      case CONTACTS_INGESTION_SERVICE.LEGACY:
        return {
          loading: legacyList.loading,
          data: legacyList.data.map((con) =>
            transformSalesforceConnectionFromLegacyToNew(con)
          ),
        };

      case CONTACTS_INGESTION_SERVICE.FLINK:
        return {
          loading: loadingList,
          data: list,
        };
      default:
        return { loading: LOADING_STATES.INIT, data: null };
    }
  }

  return getSalesforceList();
}

export function useSalesforceSyncObjectColumnsList({
  connectionId,
}: {
  connectionId: string | null;
}): SalesforceConnectionsSummary | null {
  const {
    contactIngestionEtl: { data: etl },
  } = useSelector(selectFeatureFlag);

  const { salesforceConnections } = useSelector(selectSalesforceLegacy);
  const { sfUnifiedMappingSyncObjectColumns: sfUnifiedMapping } =
    useSelector(selectSalesforce);

  const dispatch = useAppDispatch();

  useContactIngestionApiRetry();

  useEffect(() => {
    if (connectionId) {
      switch (etl) {
        case CONTACTS_INGESTION_SERVICE.LEGACY:
          dispatch(getSalesforceObjects(connectionId));
          break;

        case CONTACTS_INGESTION_SERVICE.FLINK:
          dispatch(listAllSalesforceSyncObjectColumns(connectionId));
          break;
      }
    }
  }, [dispatch, etl, connectionId]);

  function getSfObjects() {
    if (connectionId) {
      switch (etl) {
        case CONTACTS_INGESTION_SERVICE.LEGACY:
          const connection = salesforceConnections[connectionId] ?? null;
          return {
            [connectionId]: {
              loading: connection?.loading,
              sfConnectionDetails: connection?.details
                ? transformSalesforceConnectionFromLegacyToNew(
                    connection.details
                  )
                : null,
              syncObjectColumns: connection?.objects ?? null,
            },
          };

        case CONTACTS_INGESTION_SERVICE.FLINK:
          const sfConnection = sfUnifiedMapping[connectionId] ?? null;
          return {
            [connectionId]: {
              loading: sfConnection?.loading,
              sfConnectionDetails: sfConnection?.sfConnectionDetails,
              syncObjectColumns:
                transformSfSyncObjColumnsFromNewToLegacyForUnifiedMapping(
                  sfConnection?.syncObjectColumns
                ),
            },
          };
      }
    }
    return null;
  }

  return getSfObjects();
}
