import { LOADING_STATES } from "../constants/common";

export enum EXPORT_STATUS {
  QUEUED = "QUEUED",
  STARTED = "STARTED",
  EXPORTED = "EXPORTED",
  FAILED = "FAILED",
  ABORTED = "ABORTED",
  DOWNLOADED = "DOWNLOADED", // FE status for fetching the blob
}

export enum EXPORT_CAMPAIGN_REPORT_TYPE {
  ALL = "ALL",
  NOT_SENT = "NOT_SENT",
  OPEN = "OPEN",
  CLICK = "CLICK",
  BOUNCE = "BOUNCE",
  UNSUB = "UNSUB",
  SPAM = "SPAM",
}

export type ExportSummary = {
  export_id: string;
  current_status: EXPORT_STATUS;
};

export type ExportResourceResp = ExportSummary & {
  filename: string;
  requested_at: string;
};

export type ExportCampaignReport = {
  campaignId: string;
  reportType: EXPORT_CAMPAIGN_REPORT_TYPE;
  actionId: null | string;
  searchQuery: string;
  startDate: string;
  endDate: string;
};

export type ExportStatusResp = ExportSummary & {
  urls: null | string[];
};

export type ExportConfig = {
  filename: string;
  requestedAt: string;
  currentStatus: EXPORT_STATUS;
  urls: null | string[];
};

export type ExportProgressConfig = ExportConfig & { loading: LOADING_STATES };
