import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import { rollbar } from "../common/helper/rollbarHelper";
import ErrorFallback from "./ErrorFallback";

export default function ErrorBoundaryLayout() {
  const logToRollbar = (error: Error) => {
    rollbar?.error(error.name, error);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logToRollbar}>
      <Outlet />
    </ErrorBoundary>
  );
}
