import {
  Box,
  Flex,
  VStack,
  Button,
  Text,
  Link,
  HStack,
  Progress,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { SalesforceConnectionV2 } from "../../../../../common/types/salesforce";
import SubHeader from "../../components/SubHeader";
import SalesforceApiAllocationField from "./SalesforceApiAllocationField";
import {
  convertToString,
  percentageOf,
} from "../../../../../common/helper/commonHelper";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import { cloneDeep, isEqual, set } from "lodash";

const SALESFORCE_LEARN_URL =
  "https://developer.salesforce.com/docs/atlas.en-us.salesforce_app_limits_cheatsheet.meta/salesforce_app_limits_cheatsheet/salesforce_app_limits_platform_api.htm";

function ApiCallsUsed({
  usageCount,
  usageCountLoading,
  maxAllocated,
}: {
  usageCount: number | null;
  usageCountLoading: boolean;
  maxAllocated: number | undefined;
}) {
  const percentValue = useMemo(
    () => percentageOf(usageCount ?? 0, maxAllocated ?? 0) as number,
    [usageCount, maxAllocated]
  );
  return (
    <SpinnerContainer loading={usageCountLoading} width="400px">
      <VStack
        px={5}
        py="3"
        spacing="3"
        width="30%"
        h="100px"
        alignItems="flex-start"
      >
        <Text fontSize="sm">API calls used</Text>
        <Box width="100%">
          <Flex justifyContent="flex-end">
            <Text color="gray.500" fontSize="xs">
              {convertToString(usageCount ?? 0)} of{" "}
              {convertToString(maxAllocated ?? 0)}
            </Text>
          </Flex>
          <Progress rounded="md" value={percentValue} />
        </Box>
      </VStack>
    </SpinnerContainer>
  );
}

function SyncFrequencyNote({
  frequency,
  unit,
}: {
  frequency?: number | null;
  unit?: string;
}) {
  return (
    <Box color="gray.500" px="5" pb="4" fontSize="sm" width="100%">
      {frequency && unit && (
        <Flex>
          <Text>
            Note: Based on your allocation of APIs to Inflection, sync frequency
            will be approximately
            <Box as="span" fontWeight="bold" mx="1">
              {frequency}
            </Box>
            {unit}. Please note that this number is an approximate and could
            change based on several factors
          </Text>
        </Flex>
      )}
    </Box>
  );
}

export default function SfApiLimitSummary({
  connection,
  onUpdateConnection,
  refreshAction,
  isLoading,
  isUpdatingConnection,
}: {
  connection: SalesforceConnectionV2 | null;
  onUpdateConnection: (data: SalesforceConnectionV2 | null) => void;
  isUpdatingConnection?: boolean;
  refreshAction: () => void;
  isLoading: boolean;
}) {
  const salesforceLimitMax = connection?.salesforceLimits.dailyApiRequests.max;

  const [allocated, setAllocated] = useState<number | null>(
    salesforceLimitMax ?? null
  );

  function saveSyncSetting() {
    const updatedConnection = cloneDeep(connection);
    set(updatedConnection ?? {}, "inflectionLimits.dailyApiRequests", {
      max: allocated as number,
    });

    if (!isEqual(connection, updatedConnection)) {
      onUpdateConnection(updatedConnection);
    }
  }

  useEffect(() => {
    if (connection?.inflectionLimits?.dailyApiRequests) {
      setAllocated(connection?.inflectionLimits?.dailyApiRequests.max);
    }
  }, [connection?.inflectionLimits?.dailyApiRequests]);

  return (
    <Box p="5" bg="white" rounded="md">
      <SubHeader refreshAction={refreshAction} title="Sync details" />
      <Box border="1px" borderColor="gray.100" rounded="lg" mt="2">
        <Flex
          justifyContent="space-between"
          px={5}
          py={3}
          borderBottomWidth="1px"
          borderColor="gray.100"
          bg="grayV2.100"
          alignItems="center"
        >
          <VStack alignItems="left" spacing="1">
            <Text color="gray.600" fontWeight="medium" fontSize="sm">
              API call usage (past 24 hours)
            </Text>
            <Link
              color="blue.500"
              fontWeight="medium"
              fontSize="10px"
              href={SALESFORCE_LEARN_URL}
              target="_blank"
            >
              Learn more about API call limits in Salesforce
            </Link>
          </VStack>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={saveSyncSetting}
            isLoading={isLoading || isUpdatingConnection}
            isDisabled={
              !allocated ||
              allocated >
                (connection?.salesforceLimits?.dailyApiRequests.max || 15000)
            }
          >
            Save
          </Button>
        </Flex>

        <VStack alignItems="flex-start" bg="white">
          <HStack width="100%" justifyContent="space-between">
            <SalesforceApiAllocationField
              connectionDetails={connection}
              allocated={allocated}
              setAllocated={setAllocated}
            />

            <ApiCallsUsed
              usageCount={
                connection?.inflectionLimits.dailyApiRequests.used ?? null
              }
              usageCountLoading={isLoading}
              maxAllocated={salesforceLimitMax}
            />
          </HStack>
          <SyncFrequencyNote
            frequency={connection?.syncIntervalInMinutes}
            unit="minutes"
          />
        </VStack>
      </Box>
    </Box>
  );
}
