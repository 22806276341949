import {
  Box,
  BoxProps,
  Circle,
  Divider,
  Flex,
  HStack,
  Icon,
  Progress,
  Skeleton,
  SkeletonText,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { formatISO } from "date-fns";
import { isEqual, upperCase } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaEnvelopeOpen,
  FaExternalLinkAlt,
  FaInfoCircle,
  FaMousePointer,
} from "react-icons/fa";
import { MdCallMissed } from "react-icons/md";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { TOP_REPORTS_EVENT } from "../../../../common/constants/campaign";
import {
  convertToString,
  formatLargeNumber,
  isLoading,
  percentageOf,
} from "../../../../common/helper/commonHelper";
import { useDebouncedSearch } from "../../../../common/hooks/commonHooks";
import {
  AggregateStats,
  BounceClassificationStats,
  EmailClientClickData,
  EmailClientOpenData,
  EventMatrixRowType,
  EventMatrixRowListType,
} from "../../../../common/types/campaign";
import CenteredTextBox from "../../../../components/CenteredTextBox";
import CustomDateRangePicker from "../../../../components/CustomDateRangePicker";
import { DataTable } from "../../../../components/data-table/DataTable";
import IButton, { BUTTON } from "../../../../components/IButton";
import IDuplicateComponent from "../../../../components/IDuplicateComponent";
import IModal from "../../../../components/IModal";
import ITitle from "../../../../components/ITitle";
import { SearchField } from "../../../../components/SearchField";
import { useAppDispatch } from "../../../../store";
import {
  getBounceDataByClassification,
  getPaginatedTopLinks,
  selectCampaignReports,
  setBounceReasonsPage,
  setTopLinksPage,
} from "./campaignReportsSlice";
import {
  bounceClassificationListHeader,
  topLinkHeader,
} from "./constantHelper";

type EmailDataWithInfo<T> = (T & { info?: string })[];

enum TOOLTIP_KEYS {
  SENT = "sent",
  DELIVERED = "delivered",
  BOUNCED = "bounced",
  UNSUB = "unsub",
  SPAM = "spam",
  OPENS = "opens",
  TOTAL_OPENS = "total_opens",
  CLICKS = "clicks",
  TOTAL_CLICKS = "total_clicks",
  UNIQUE_OPEN_CLICK_RATIO = "unique_click_open_ratio",
}

const INFORMATIONS = {
  [TOOLTIP_KEYS.SENT]:
    "This is the number of emails sent from Inflection to customers. Some of the qualified recipients will be excluded from this list as they might be unsubscribed or previously hardbounced contacts. Inflection also excludes blacklisted or invalid email id to protect your sender reputation",
  [TOOLTIP_KEYS.DELIVERED]:
    "This shows the number of emails that got delivered to the recipient. The delivery rate is calculated by the total number of delivered emails divided by the total number of emails sent. Bounced emails are excluded from the calculation.",
  [TOOLTIP_KEYS.BOUNCED]:
    "This is the number of email which were hard bounced. These emails will be blacklisted automatically by Inflection. The bounce rate is calculated by the number of email bounced divided by the total number of emails sent",
  [TOOLTIP_KEYS.UNSUB]:
    "This is the total number of opt-out requests from the contact side. The unsubscription rate is calculated the number of unsubscribe requests divided by total number of emails delivered. Unsubscription request for an email id is only counted once",
  [TOOLTIP_KEYS.SPAM]:
    "These represents the number of cases where the recipient reported the email as spam. The spam report rate is calculated by the number of spam reports divided by total number of emails delivered.",
  [TOOLTIP_KEYS.OPENS]:
    "The number of unique recipients who opened this email. A recipient is only counted once.",
  [TOOLTIP_KEYS.TOTAL_OPENS]:
    "The total number of times the email was opened by recipients. This includes multiple opens by the same recipients.",
  [TOOLTIP_KEYS.CLICKS]:
    "The number of unique recipients who clicked on any link in the email. This includes multiple clicks by the same recipients.",
  [TOOLTIP_KEYS.TOTAL_CLICKS]:
    "The total number of times links were clicked by recipients. This includes multiple clicks by the same recipients.",
  [TOOLTIP_KEYS.UNIQUE_OPEN_CLICK_RATIO]:
    "This is the percentage of Clicks divided by the Openings. This metric shows the number of recipients who clicked a link after opening the email",
};

const PERCENTAGE_THRESHOLD = 0.03;

export function InfoToolTip({ info, ...props }: { info: string } & BoxProps) {
  return (
    <Tooltip label={info} hasArrow bgColor="gray.100" color="gray.600">
      <HStack color="gray.400" {...props}>
        <Icon as={FaInfoCircle} />
      </HStack>
    </Tooltip>
  );
}

export function DeliveryInfoCard({
  isTotalCard,
  value,
  total,
  label,
  info,
}: {
  isTotalCard: boolean;
  value: number;
  total: number;
  label: string;
  info: string;
}) {
  return (
    <VStack
      alignItems="left"
      justifyContent="center"
      flex="1"
      height="150px"
      border="1px solid"
      borderColor="gray.300"
      rounded="md"
      px={5}
      position="relative"
    >
      {!isTotalCard && (
        <Text fontSize="18px" fontWeight="bolder" color="blue.600">
          {percentageOf(value, total, true)}
        </Text>
      )}
      <Text fontSize="20px" fontWeight="bolder">
        <NumberFormat
          value={formatLargeNumber(value, 2)}
          isNumericString={true}
          thousandSeparator={true}
          displayType="text"
        />
      </Text>
      <Text fontSize="16px" color="gray.400" fontWeight="500">
        {label}
      </Text>
      {info && (
        <InfoToolTip info={info} position="absolute" right="8px" top="0" />
      )}
    </VStack>
  );
}

export function DeliveryInfo() {
  const {
    aggregateData: { data },
  } = useSelector(selectCampaignReports);

  type MatrixDataType = {
    name: string;
    key: keyof AggregateStats;
    denominator: keyof AggregateStats;
    info: string;
  };

  const matrixData: MatrixDataType[] = [
    {
      name: "Emails Processed",
      key: "total_count",
      denominator: "total_count",
      info: "",
    },
    {
      name: "Sent",
      key: "processed_count",
      denominator: "total_count",
      info: INFORMATIONS[TOOLTIP_KEYS.SENT],
    },
    {
      name: "Delivered",
      key: "delivered_count",
      denominator: "processed_count",
      info: INFORMATIONS[TOOLTIP_KEYS.DELIVERED],
    },
    {
      name: "Bounced",
      key: "bounce_count",
      denominator: "processed_count",
      info: INFORMATIONS[TOOLTIP_KEYS.BOUNCED],
    },
    {
      name: "Unsubscribed",
      key: "unsub_count",
      denominator: "delivered_count",
      info: INFORMATIONS[TOOLTIP_KEYS.UNSUB],
    },
    {
      name: "Report Spam",
      key: "spamreport_count",
      denominator: "delivered_count",
      info: INFORMATIONS[TOOLTIP_KEYS.SPAM],
    },
  ];

  return (
    <Box width="100%">
      <ITitle
        title="Delivery Stats"
        fontSize="18px"
        fontWeight="bold"
        mb="12px"
        capitalize={false}
      />
      <HStack width="100%">
        {matrixData.map((item, index) => {
          return (
            <DeliveryInfoCard
              isTotalCard={!index}
              value={data[item.key]}
              total={data[item.denominator]}
              label={item.name}
              info={item.info}
              key={index}
            />
          );
        })}
      </HStack>
    </Box>
  );
}

export function BounceReasonsListModal({
  isOpen,
  bounceClassification,
  onClose,
}: {
  isOpen: boolean;
  bounceClassification: string;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();

  const {
    bounceStats: {
      bounceStatsByClassification,
      bounceClassificationInfo: {
        data: bounceClassificationInfo,
        loading: isClassificationsLoading,
      },
    },
    filterData: { timeRange },
  } = useSelector(selectCampaignReports);

  const [bounceTimeRange, setBounceTimeRange] = useState(timeRange);

  useEffect(() => {
    setBounceTimeRange(timeRange);
  }, [timeRange]);

  const searchIfValid = useCallback(
    (keyword: string) => {
      dispatch(
        getBounceDataByClassification({
          classification: bounceClassification,
          searchKeyword: keyword,
          timeRange: bounceTimeRange,
        })
      );
    },
    [dispatch, bounceClassification, bounceTimeRange]
  );

  useEffect(() => {
    if (isOpen) {
      searchIfValid(searchKeyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    bounceStatsByClassification.currentPageNo,
    isOpen,
    searchIfValid,
  ]);

  // TODO: Find a better way to do search than ignoring lint errors

  function onCloseModal() {
    setSearchKeyword("");
    onClose();
    dispatch(setBounceReasonsPage(1));
  }

  const [searchKeyword, setSearchKeyword] = useState("");

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  const onSearch = useCallback(
    (keyword: string) => {
      setSearchKeyword(keyword);
      debouncedSearch(keyword.trim());
    },
    [debouncedSearch]
  );

  function onDateChange(start: Date, end: Date) {
    const startDate = formatISO(start);
    const endDate = formatISO(end);
    if (
      !(
        isEqual(bounceTimeRange.startDate, startDate) &&
        isEqual(bounceTimeRange.endDate, endDate)
      )
    ) {
      setBounceTimeRange({ startDate, endDate });
    }
  }

  const classificationInfo = bounceClassificationInfo[bounceClassification];

  return (
    <IModal
      isOpen={isOpen}
      size="4xl"
      onClose={onCloseModal}
      header={{ title: "Email bounced", capitalize: false }}
    >
      <VStack w="100%" height="70vh" overflow="auto" spacing={4}>
        <VStack alignItems="flex-start" fontSize="14px" w="100%" spacing={4}>
          <Skeleton
            minW="150px"
            minH="22px"
            isLoaded={!isLoading(isClassificationsLoading)}
          >
            <Text color="gray.600">{classificationInfo?.display}</Text>
          </Skeleton>
          <SkeletonText
            noOfLines={2}
            skeletonHeight="16px"
            w="100%"
            isLoaded={!isLoading(isClassificationsLoading)}
          >
            <Text>{classificationInfo?.description}</Text>
          </SkeletonText>
        </VStack>
        <HStack w="100%" justifyContent="right">
          <CustomDateRangePicker
            startDate={new Date(bounceTimeRange.startDate)}
            endDate={new Date(bounceTimeRange.endDate)}
            onDateRangeChange={onDateChange}
          />
          <SearchField
            name="search-bounce-email"
            value={searchKeyword}
            onSearch={onSearch}
            w="250px"
            placeholder="Search recipient email"
          />
        </HStack>
        <DataTable
          fetchingList={bounceStatsByClassification.fetchingList}
          changingPage={bounceStatsByClassification.changingPage}
          list={bounceStatsByClassification.list}
          totalPageSize={bounceStatsByClassification.pageSize}
          totalPageCount={bounceStatsByClassification.totalPageCount}
          currentPage={bounceStatsByClassification.currentPageNo}
          setPage={(pageNo) => dispatch(setBounceReasonsPage(pageNo))}
          columns={bounceClassificationListHeader}
          emptyMsg="No bounces for the given filter"
        />
      </VStack>
    </IModal>
  );
}

function EventRateMatrixRowSkeleton({ isLoading }: { isLoading: boolean }) {
  return (
    <VStack alignItems="left" divider={<Divider />}>
      <IDuplicateComponent duplicateCount={2}>
        <HStack w="100%" justifyContent="space-between" mb={3}>
          <Skeleton w="60%" h="24px" isLoaded={!isLoading} />
          <Skeleton w="30px" h="24px" isLoaded={!isLoading} />
        </HStack>
      </IDuplicateComponent>
    </VStack>
  );
}

function UniqueClicksPerUniqueOpens({
  uniqueClickCount = 0,
  uniqueOpenCount = 0,
}: {
  uniqueClickCount?: number;
  uniqueOpenCount?: number;
}) {
  return (
    <HStack w="100%" justifyContent="space-between">
      <HStack>
        <Text>Unique clicks per unique open</Text>{" "}
        <InfoToolTip
          info={INFORMATIONS[TOOLTIP_KEYS.UNIQUE_OPEN_CLICK_RATIO]}
        />
      </HStack>
      <HStack>
        <Text>{percentageOf(uniqueClickCount, uniqueOpenCount, true)}</Text>
      </HStack>
    </HStack>
  );
}

function EventRateMatrixRow({
  label,
  info,
  value,
  expandDetails,
  showPercentageOnRows,
  totalMail,
}: {
  showPercentageOnRows?: boolean;
  totalMail: number;
} & EventMatrixRowType) {
  return (
    <HStack w="100%" justifyContent="space-between">
      <HStack>
        <Text>{label} </Text>
        {info && <InfoToolTip info={info} />}
        {expandDetails && (
          <Icon
            as={FaExternalLinkAlt}
            onClick={expandDetails}
            color="brand.blue"
            cursor="pointer"
            fontSize="12px"
          />
        )}
      </HStack>
      <HStack spacing={3}>
        {showPercentageOnRows && (
          <Text color="text.50">
            {percentageOf(value ?? 0, totalMail, true)}
          </Text>
        )}
        <Text>{convertToString(value)}</Text>
      </HStack>
    </HStack>
  );
}

export function EventRateMatrix({
  type,
  uniqueEventCount,
  totalEventCount,
  totalMail,
  clickCount,
  bounceReasons,
  expandDetails,
  isLoadingRows,
  showPercentageOnRows,
}: {
  type: TOP_REPORTS_EVENT;
  uniqueEventCount: number;
  totalEventCount: number;
  totalMail: number;
  clickCount?: number;
  bounceReasons?: BounceClassificationStats[];
  expandDetails?: (classification: string) => void;
  isLoadingRows?: boolean;
  showPercentageOnRows?: boolean;
}) {
  const [isShownMore, setIsShownMore] = useState(false);

  const EVENT_ROWS_PER_TYPE: Record<TOP_REPORTS_EVENT, EventMatrixRowListType> =
    {
      [TOP_REPORTS_EVENT.OPEN]: {
        icon: <FaEnvelopeOpen />,
        rows: [
          {
            label: "Unique opens",
            info: INFORMATIONS[TOOLTIP_KEYS.OPENS],
            value: uniqueEventCount,
          },
          {
            label: "Total opens",
            info: INFORMATIONS[TOOLTIP_KEYS.TOTAL_OPENS],
            value: totalEventCount,
          },
          {
            cell: (
              <UniqueClicksPerUniqueOpens
                uniqueClickCount={clickCount}
                uniqueOpenCount={uniqueEventCount}
              />
            ),
          },
        ],
      },
      [TOP_REPORTS_EVENT.CLICK]: {
        icon: <FaMousePointer style={{ marginLeft: "2px" }} />,
        rows: [
          {
            label: "Unique clicks",
            info: INFORMATIONS[TOOLTIP_KEYS.CLICKS],
            value: uniqueEventCount,
          },
          {
            label: "Total clicks",
            info: INFORMATIONS[TOOLTIP_KEYS.TOTAL_CLICKS],
            value: totalEventCount,
          },
        ],
      },
      [TOP_REPORTS_EVENT.BOUNCE]: {
        icon: <MdCallMissed />,
        rows:
          bounceReasons?.map((reason) => {
            return {
              label: reason.display || reason.bounce_classification,
              value: reason.count,
              expandDetails: () =>
                expandDetails?.(reason.bounce_classification),
            };
          }) ?? [],
      },
    };

  const event = EVENT_ROWS_PER_TYPE[type];

  return (
    <VStack alignItems="left" flex="1" spacing={8}>
      <Circle bgColor="blue.50" size="40px">
        {event.icon}
      </Circle>
      <VStack alignItems="left">
        <Text color="gray.400" fontSize="14px">
          {upperCase(`${type} Rate`)}
        </Text>
        <Text fontSize="24px" fontWeight="bold">
          {percentageOf(uniqueEventCount, totalMail, true)}
        </Text>
      </VStack>
      <Progress value={percentageOf(uniqueEventCount, totalMail) as number} />
      {isLoadingRows ? (
        <EventRateMatrixRowSkeleton isLoading={isLoadingRows} />
      ) : (
        <VStack alignItems="left" spacing={4} divider={<Divider />}>
          {event.rows
            .slice(0, isShownMore ? event.rows.length : 3)
            .map(({ label, info, value, cell, expandDetails }) => {
              if (cell) return cell;
              return (
                <EventRateMatrixRow
                  label={label}
                  info={info}
                  value={value}
                  expandDetails={expandDetails}
                  showPercentageOnRows={showPercentageOnRows}
                  totalMail={totalMail}
                />
              );
            })}
          {event.rows.length > 3 && (
            <HStack
              spacing={1}
              alignItems="flex-end"
              onClick={() => setIsShownMore(!isShownMore)}
              color="brand.blue"
              fontSize="12px"
              cursor="pointer"
              fontWeight="600"
            >
              <Text>Show {isShownMore ? "less" : "more"}</Text>
              <Icon as={isShownMore ? FaChevronUp : FaChevronDown} pb="3px" />
            </HStack>
          )}
        </VStack>
      )}
    </VStack>
  );
}

export function EventMatrix() {
  const {
    aggregateData: { data },
    bounceStats: {
      bounceOverAllStats,
      bounceClassificationInfo: {
        data: bounceClassificationInfo,
        loading: isClassificationsLoading,
      },
    },
  } = useSelector(selectCampaignReports);

  const [bounceClassification, setBounceClassification] = useState("");

  return (
    <HStack alignItems="flex-start" w="100%" spacing={10} px={2} py={10}>
      <EventRateMatrix
        type={TOP_REPORTS_EVENT.OPEN}
        uniqueEventCount={data.unique_open_count}
        totalEventCount={data.total_open_count}
        totalMail={data.delivered_count}
        clickCount={data.unique_click_count_by_email}
      />
      <EventRateMatrix
        type={TOP_REPORTS_EVENT.CLICK}
        uniqueEventCount={data.unique_click_count_by_email}
        totalEventCount={data.total_click_count}
        totalMail={data.delivered_count}
      />
      <EventRateMatrix
        type={TOP_REPORTS_EVENT.BOUNCE}
        uniqueEventCount={data.bounce_count}
        totalEventCount={data.bounce_count}
        totalMail={data.processed_count}
        bounceReasons={bounceOverAllStats.data.map((row) => {
          return {
            ...row,
            display:
              bounceClassificationInfo[row.bounce_classification]?.display,
          };
        })}
        isLoadingRows={isLoading(isClassificationsLoading)}
        expandDetails={setBounceClassification}
        showPercentageOnRows={true}
      />
      <BounceReasonsListModal
        isOpen={!!bounceClassification}
        bounceClassification={bounceClassification}
        onClose={() => setBounceClassification("")}
      />
    </HStack>
  );
}

const CLIENT_LIMIT = 10;

export function EmailClient() {
  const {
    emailClient: {
      open: { data: openClientData },
      click: { data: clickClientData },
    },
  } = useSelector(selectCampaignReports);

  function ClientBarGraph({
    label,
    value,
    total,
    info,
  }: {
    label: string;
    value: number;
    total: number;
    info?: string;
  }) {
    if (!value) return <></>;

    const percentage = percentageOf(value, total, true);
    return (
      <HStack w="100%">
        <Flex w="25%" wordBreak="break-all" gap={2}>
          {label} {info && <InfoToolTip info={info} />}
        </Flex>
        <Flex w="75%" alignItems="center">
          <Flex
            w={`calc(${percentage} - 15px)`}
            h="24px"
            justifyContent="flex-end"
            alignItems="center"
            backgroundColor="blue.500"
          />
          <Flex color="blue.500" pl={1} fontSize="14px">
            {percentage}
          </Flex>
        </Flex>
      </HStack>
    );
  }

  // limiting clients name list to a certain number and add misc at end.
  function limitByCount(
    existingClients: string,
    newClients: string,
    limit: number
  ) {
    const clients = [
      ...(existingClients ? existingClients.split(", ") : []),
      newClients,
    ];
    if (clients.length > limit) {
      return clients
        .slice(0, limit)
        .join(", ")
        .replaceAll("and misc.", "")
        .concat(" and misc.");
    }
    return clients.join(", ");
  }

  // Combining email clients with open rate less than 3 percent under "Others"
  const clientOpenTotal = openClientData.reduce((sum, data) => {
    return sum + data.unique_open_count;
  }, 0);
  const othersOpenClientData = {
    email_client: "Others",
    unique_open_count: 0,
    total_open_count: 0,
    info: "",
  };
  const openClientUpdatedData: EmailDataWithInfo<EmailClientOpenData> = [];
  openClientData.forEach((item) => {
    if (item.unique_open_count / clientOpenTotal < PERCENTAGE_THRESHOLD) {
      othersOpenClientData.unique_open_count += item.unique_open_count;
      othersOpenClientData.total_open_count += item.total_open_count;
      othersOpenClientData.info = limitByCount(
        othersOpenClientData.info,
        item.email_client,
        CLIENT_LIMIT
      );
    } else {
      openClientUpdatedData.push(item);
    }
  });

  //inserting 'others' to a sorted email client list
  if (othersOpenClientData.unique_open_count) {
    const position = openClientUpdatedData.findIndex(
      (data) => data.unique_open_count < othersOpenClientData.unique_open_count
    );
    openClientUpdatedData.splice(
      position > -1 ? position : openClientUpdatedData.length,
      0,
      othersOpenClientData
    );
  }

  // Combining email clients with click rate less than 3 percent under "Others"
  const clientClickTotal = clickClientData.reduce((sum, data) => {
    return sum + data.unique_click_count_by_url;
  }, 0);
  const othersClickClientData = {
    email_client: "Others",
    unique_click_count_by_email: 0,
    unique_click_count_by_url: 0,
    total_click_count: 0,
    info: "",
  };
  const clickClientUpdatedData: EmailDataWithInfo<EmailClientClickData> = [];
  clickClientData.forEach((item) => {
    if (
      item.unique_click_count_by_url / clientClickTotal <
      PERCENTAGE_THRESHOLD
    ) {
      othersClickClientData.unique_click_count_by_url +=
        item.unique_click_count_by_url;
      othersClickClientData.total_click_count += item.total_click_count;
      othersClickClientData.info = limitByCount(
        othersClickClientData.info,
        item.email_client,
        CLIENT_LIMIT
      );
    } else {
      clickClientUpdatedData.push(item);
    }
  });

  //inserting 'others' to a sorted email client list
  if (othersClickClientData.unique_click_count_by_url) {
    const position = clickClientUpdatedData.findIndex(
      (data) =>
        data.unique_click_count_by_url <
        othersClickClientData.unique_click_count_by_url
    );
    clickClientUpdatedData.splice(
      position > -1 ? position : clickClientUpdatedData.length,
      0,
      othersClickClientData
    );
  }

  return (
    <Tabs size="md" variant="line" w="100%">
      <Flex alignItems="baseline">
        <ITitle
          title="Email Clients"
          fontSize="18px"
          fontWeight="bold"
          capitalize={false}
        />
        <TabList borderBottom={1} ml={4}>
          <Tab>Open</Tab>
          <Tab>Click</Tab>
        </TabList>
      </Flex>
      <Text py={3} fontSize="14px" color="gray.400">
        Note: Due to iOS privacy settings, iOS open rates will be reported as
        higher than the actual rate.
      </Text>
      <TabPanels>
        <TabPanel p={0}>
          <VStack>
            {openClientData.length ? (
              openClientUpdatedData.map((client, index) => (
                <ClientBarGraph
                  label={client.email_client}
                  value={client.unique_open_count}
                  total={clientOpenTotal}
                  info={client.info}
                  key={index}
                />
              ))
            ) : (
              <CenteredTextBox message="No email client open data" />
            )}
          </VStack>
        </TabPanel>
        <TabPanel p={0}>
          <VStack>
            {clickClientData.length ? (
              clickClientUpdatedData.map((client, index) => (
                <ClientBarGraph
                  label={client.email_client}
                  value={client.unique_click_count_by_url}
                  total={clientClickTotal}
                  info={client.info}
                  key={index}
                />
              ))
            ) : (
              <CenteredTextBox message="No email client click data" />
            )}
          </VStack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export function TopLinksModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const {
    topLinks: { list: topLinkList },
  } = useSelector(selectCampaignReports);

  useEffect(() => {
    if (isOpen) {
      dispatch(getPaginatedTopLinks());
    }
  }, [dispatch, topLinkList.currentPageNo, isOpen]);

  return (
    <IModal
      isOpen={isOpen}
      size="4xl"
      onClose={() => {
        onClose();
        dispatch(setTopLinksPage(1));
      }}
      header={{ title: "Email Links", capitalize: false }}
    >
      <Box maxHeight="70vh" overflow="auto">
        <DataTable
          fetchingList={topLinkList.fetchingList}
          changingPage={topLinkList.changingPage}
          list={topLinkList.list}
          totalPageSize={topLinkList.pageSize}
          totalPageCount={topLinkList.totalPageCount}
          currentPage={topLinkList.currentPageNo}
          setPage={(pageNo) => dispatch(setTopLinksPage(pageNo))}
          columns={topLinkHeader}
          emptyMsg="No links"
        />
      </Box>
    </IModal>
  );
}

export function TopLinks() {
  const {
    topLinks: {
      top: { data },
    },
  } = useSelector(selectCampaignReports);

  const { isOpen, onClose, onOpen } = useDisclosure();

  function TableRender() {
    return (
      <Table borderWidth="1px" fontSize="sm" minW="50vw" mb={4}>
        <Thead bg="grayV2.100">
          {/* table header case handling */}
          <Tr>
            <Th scope="col">LINK</Th>
            <Th scope="col">NUMBER OF CLICKS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) => (
            <Tr key={index}>
              <Td width="70%" wordBreak="break-all">
                {row.link}
              </Td>
              <Td whiteSpace="nowrap" key={index}>
                {convertToString(row.unique_click_count_by_url)}
              </Td>
            </Tr>
          ))}
          {data.length ? (
            <Tr>
              <Td>
                <IButton
                  variant={BUTTON.SECONDARY}
                  margin="8px 16px"
                  size="sm"
                  fontSize="14px"
                  onClick={onOpen}
                  name="view-all-links"
                >
                  View all links
                </IButton>
              </Td>
            </Tr>
          ) : (
            <Tr h="100px">
              <Td></Td>
              <Td color="gray.500">No data available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  }

  return (
    <Box w="100%">
      <ITitle
        title="Top Links"
        fontSize="18px"
        fontWeight="bold"
        mb="12px"
        capitalize={false}
      />
      <TableRender />
      <TopLinksModal onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}
