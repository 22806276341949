import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  useDisclosure,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SecondarySidebar from "../../../components/v2/SecondarySidebar";
import {
  FormError,
  FORM_STATUS,
  FORM_VENDORS,
  ProviderDetails,
} from "../../../common/types/form";
import SidebarBackButton from "../../../components/v2/SidebarBackButton";
import urls from "../../../urls";
import SideBar from "../../../components/SideBar";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import ContentContainer from "../../../components/v2/ContentContainer";
import IButton from "../../../components/IButton";
import CopyText from "./components/CopyText";
import WebflowVersionDeprecationBanner from "./components/WebflowVersionDeprecationBanner";
import DisconnectProvider from "./components/DisconnectProvider";
import {
  disconnectFormsProvider,
  listFormProviders,
  selectForm,
  resetProvidersList,
  getFormProviderErrors,
  setFormProviderErrorsPage,
  listAllForms,
} from "./formSlice";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import {
  isFulfilled,
  isLoading,
  isSuccess,
} from "../../../common/helper/commonHelper";
import SpinnerContainer from "../../../components/SpinnerContainer";
import { STATUS } from "../../../common/types/unifiedMapping";
import { LOADING_STATES } from "../../../common/constants/common";
import { DataTable } from "../../../components/data-table/DataTable";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { createColumnHelper } from "@tanstack/react-table";
import ErrorsSidebar from "./components/ErrorsSidebar";
import LabelValuePair from "../../../components/LabelValuePair";
import { isBefore } from "date-fns";

function BoxContainer({ children }: { children: ReactNode }) {
  return (
    <Box bg="white" p={{ base: "3", md: "5" }} w="100%" rounded="md">
      {children}
    </Box>
  );
}

function FormProviderStats({
  name,
  vendor,
}: {
  name: string;
  vendor: FORM_VENDORS;
}) {
  const dispatch = useAppDispatch();

  const { allFormsSummary } = useSelector(selectForm);

  const allForms = useMemo(() => {
    if (isSuccess(allFormsSummary.loading)) {
      return allFormsSummary.data.filter((x) => x.vendor === vendor);
    } else {
      return [];
    }
  }, [allFormsSummary.data, allFormsSummary.loading, vendor]);

  const allActiveForms = useMemo(() => {
    if (allForms && allForms.length) {
      return allForms.filter((x) => x.form_status === FORM_STATUS.READY);
    }
  }, [allForms]);

  const firstConnectedDate = useMemo(() => {
    if (allForms && allForms.length) {
      const sorted = [...allForms].sort((a, b) =>
        isBefore(new Date(a.created_at), new Date(b.created_at)) ? -1 : 1
      );
      return sorted[0].created_at;
    }
  }, [allForms]);

  useEffect(() => {
    dispatch(listAllForms());
  }, [dispatch]);

  const items = [
    {
      label: "Date connected",
      value: <FormatDate date={firstConnectedDate} showTime />,
      isLoading: false,
    },
    {
      label: "Active forms",
      value: allActiveForms?.length ?? "-",
      isLoading: false,
    },
    {
      label: "Total forms",
      value: allForms?.length ?? "-",
      isLoading: false,
    },
  ];

  return (
    <VStack alignItems="flex-start" w="100%">
      <Text fontWeight={600} color="text.50" fontSize="lg">
        {name} connection details
      </Text>
      <HStack
        alignItems="flex-start"
        rounded="md"
        bgColor="white"
        w="100%"
        py={2}
        spacing={8}
      >
        {items.map((item, index) => {
          return (
            <>
              <LabelValuePair
                label={item.label}
                value={item.value}
                isLoadingData={item.isLoading}
              />
              {index !== items.length - 1 && (
                <Divider height="55px" orientation="vertical" />
              )}
            </>
          );
        })}
      </HStack>
    </VStack>
  );
}

function ConnectionDetails({
  isDisabledProvider,
  onOpen,
  disconnectingProvider,
  providerDetails,
  vendor,
}: {
  isDisabledProvider: boolean;
  onOpen: () => void;
  disconnectingProvider: LOADING_STATES;
  providerDetails:
    | ProviderDetails
    | { webhook_url: string; api_key: string; name: string; state: STATUS };
  vendor: string | undefined;
}) {
  return (
    <ContentContainerWithHeader>
      <HStack h="65px" justifyContent="flex-end" py={1} alignItems="center">
        {!isDisabledProvider && (
          <IButton
            variant="solid"
            colorScheme="red"
            onClick={onOpen}
            isLoading={isLoading(disconnectingProvider)}
          >
            Disconnect
          </IButton>
        )}
      </HStack>
      <ContentContainer
        flexDir="column"
        bg="unset"
        maxH="calc(100vh - 60px)"
        p="unset"
      >
        {isDisabledProvider ? (
          <Center h="20vh" bg="white">
            <Text color="gray.500">Provider is not connected.</Text>
          </Center>
        ) : (
          <VStack w="100%" alignItems="flex-start" spacing={4}>
            <BoxContainer>
              <FormProviderStats
                name={providerDetails.name}
                vendor={vendor as FORM_VENDORS}
              />
            </BoxContainer>
            <BoxContainer>
              <VStack alignItems="flex-start" spacing={4} bg="white">
                <Box w="500px">
                  <Text fontWeight={600} color="text.50" fontSize="lg">
                    Webhook details
                  </Text>
                  <CopyText
                    textType="webhook"
                    text={providerDetails.webhook_url ?? ""}
                  />
                </Box>
                {vendor === FORM_VENDORS.FORMSTACK && (
                  <Box w="500px">
                    <Text fontWeight={600} color="text.50" fontSize="lg">
                      Shared secret details
                    </Text>
                    <CopyText
                      text={providerDetails.api_key ?? ""}
                      textType="text"
                    />
                  </Box>
                )}
                {vendor === FORM_VENDORS.WEBFLOW && (
                  <WebflowVersionDeprecationBanner />
                )}
              </VStack>
            </BoxContainer>
          </VStack>
        )}
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}

function ConnectionErrors({ vendor }: { vendor: FORM_VENDORS }) {
  const dispatch = useAppDispatch();

  const { formProviderErrors } = useSelector(selectForm);

  useEffect(() => {
    return function resetPage() {
      dispatch(setFormProviderErrorsPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    if (vendor)
      dispatch(
        getFormProviderErrors({
          source: vendor,
        })
      );
  }, [dispatch, formProviderErrors.currentPageNo, vendor]);

  const columnHelper = createColumnHelper<FormError>();
  const [selectedError, setSelectedError] = useState<FormError | null>(null);

  const columns = useMemo(
    () => [
      columnHelper.accessor("error", {
        header: "Errors",
        cell: (info) => <Text noOfLines={1}>{info.getValue()}</Text>,
        minSize: 400,
      }),
      columnHelper.accessor("received_date", {
        header: "Submission date",
        cell: (info) => <FormatDate date={info.getValue()} />,
        minSize: 200,
      }),
    ],
    [columnHelper]
  );

  return (
    <Box mt="65px" px="15px" w="100%">
      <DataTable
        list={formProviderErrors.list}
        fetchingList={formProviderErrors.fetchingList}
        changingPage={formProviderErrors.changingPage}
        setPage={(pageNo) => dispatch(setFormProviderErrorsPage(pageNo))}
        onRowClick={setSelectedError}
        totalPageCount={formProviderErrors.totalPageCount}
        totalPageSize={formProviderErrors.pageSize}
        currentPage={formProviderErrors.currentPageNo}
        columns={columns}
        emptyMsg="No errors found"
      />

      <ErrorsSidebar
        selectedError={selectedError}
        onClose={() => setSelectedError(null)}
      />
    </Box>
  );
}

export default function FormProvider() {
  const {
    providersList: { data: providersList, loading },
    disconnectingProvider,
  } = useSelector(selectForm);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { vendor } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);

  const providerDetails = useMemo(
    () =>
      providersList.length
        ? providersList[0]
        : { webhook_url: "", api_key: "", name: "", state: STATUS.DISABLED },
    [providersList]
  );

  const isDisabledProvider = useMemo(
    () => providerDetails.state === STATUS.DISABLED,
    [providerDetails]
  );

  useEffect(() => {
    dispatch(listFormProviders(vendor as FORM_VENDORS));
    return () => {
      dispatch(resetProvidersList());
    };
  }, [dispatch, vendor]);

  async function disconnectProvider() {
    const {
      meta: { requestStatus },
    } = await dispatch(disconnectFormsProvider(providerDetails.api_key));
    isFulfilled(requestStatus) && navigate(urls.unifiedMapping);
  }

  const tabs: { [key: number]: ReactNode } = {
    0: (
      <ConnectionDetails
        isDisabledProvider={isDisabledProvider}
        onOpen={onOpen}
        disconnectingProvider={disconnectingProvider}
        providerDetails={providerDetails}
        vendor={vendor}
      />
    ),
    1: <ConnectionErrors vendor={vendor as FORM_VENDORS} />,
  };

  return (
    <>
      <Flex h="100vh">
        <SecondarySidebar
          heading={providerDetails.name}
          backButton={
            <SidebarBackButton
              onClick={() => navigate(urls.unifiedMapping)}
              text="Back to Data Connections"
            />
          }
        >
          <SideBar
            options={["Connection details", "Connection health"]}
            selected={tabIndex}
            select={setTabIndex}
          />
        </SecondarySidebar>
        <SpinnerContainer loading={isLoading(loading)} h="calc(100vh - 75px)">
          {tabs[tabIndex]}
        </SpinnerContainer>
      </Flex>
      <DisconnectProvider
        isOpen={isOpen}
        onClose={onClose}
        providerName={providerDetails.name}
        onDisconnect={disconnectProvider}
      />
    </>
  );
}
