import {
  Circle,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { ReactNode, useContext, useState } from "react";
import { IconType } from "react-icons";
import ITitle from "../../../../../components/ITitle";
import { useAppDispatch } from "../../../../../store";
import { FaExclamation, FaPencilAlt } from "react-icons/fa";
import {
  FlowStepSourceHandle,
  FlowStepTargetHandle,
  GotoLeftHandle,
  GotoRightHandle,
} from "./FlowHandle";
import {
  ActionNodeIdentifiers,
  FlowContactCount,
} from "../../../../../common/types/flow";
import WrapperForCandidate from "./WrapperForCandidate";
import WidgetIcon from "./WidgetIcon";
import WidgetIconButton from "./WidgetIconButton";
import { BiSolidTrash } from "react-icons/bi";
import {
  CampaignBuilderContext,
  removeFlowAction,
  removeFlowActionValidation,
} from "../flowSlice";
import { isFulfilled } from "../../../../../common/helper/commonHelper";
import { toast } from "react-toastify";

import { ConfirmationModal } from "../../../../../components/ConfirmationModal";

export default function WidgetContainer({
  invalidMessage,
  identities,
  icon,
  title,
  color,
  children,
  isCandidate,
  selectedGoto,
  selectedExit,
  isDisabled,
  onClickEdit,
}: {
  invalidMessage: string;
  identities: ActionNodeIdentifiers;
  icon: IconType;
  color: string;
  title: string;
  children: ReactNode;
  isCandidate?: boolean;
  selectedGoto?: string;
  selectedExit?: { actionId: string; conditionId: string };
  isDisabled?: boolean;
  onClickEdit?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { campaignId, activeErrorCheck } = useContext(CampaignBuilderContext);
  const [count, setCount] = useState(0);
  const [isFetchingCount, setIsFetchingCount] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function removeAction() {
    setIsDeleting(true);
    await dispatch(
      removeFlowAction({
        campaignId,
        actionId: identities.actionId,
        groupId: identities.groupId,
      })
    );
    setIsDeleting(false);
  }

  async function removeActionValidation() {
    setIsFetchingCount(true);
    const response = await dispatch(
      removeFlowActionValidation({
        campaignId,
        actionId: identities.actionId,
      })
    );

    if (isFulfilled(response.meta.requestStatus)) {
      setIsFetchingCount(false);
      const resPayload = response.payload as FlowContactCount;
      setCount(resPayload.member_count_in_progress);
      onOpen();
    } else {
      setIsFetchingCount(false);
      toast.error("Error while removing flow step");
    }
  }
  const theme = useTheme();
  const red400 = theme.__cssVars["--chakra-colors-red-400"];

  function handleCancel() {
    onClose();
  }

  function handleConfirm() {
    removeAction();
  }

  return (
    <WrapperForCandidate
      isCandidate={isCandidate}
      identities={identities}
      selectedGoto={selectedGoto}
      selectedExit={selectedExit}
      isInvalid={activeErrorCheck && !!invalidMessage}
    >
      <Flex
        rounded="xl"
        bg="white"
        w="md"
        alignItems="center"
        flexDir="column"
        cursor="initial"
        position="relative"
        className="nopan"
      >
        <FlowStepTargetHandle />
        <GotoRightHandle />
        <GotoLeftHandle />
        <FlowStepSourceHandle />
        <Flex
          w="full"
          justifyContent="space-between"
          pt="5"
          px="5"
          alignItems="center"
        >
          <Flex alignItems="center">
            <WidgetIcon
              color={activeErrorCheck && invalidMessage ? red400 : color}
              mr="2"
              icon={icon}
            />
            <ITitle
              title={title}
              fontSize="md"
              color="text.100"
              fontWeight="bold"
              capitalize={false}
            />
            {activeErrorCheck && invalidMessage && (
              <Tooltip
                label={invalidMessage}
                bg="red.400"
                color="white"
                hasArrow
              >
                <Circle
                  bg="red.400"
                  ml={3}
                  position="absolute"
                  right={-2.5}
                  top={-2}
                  p={1}
                >
                  <Icon fontSize="12" color="white" as={FaExclamation} />
                </Circle>
              </Tooltip>
            )}
          </Flex>
          <HStack>
            {onClickEdit && (
              <WidgetIconButton
                onClick={onClickEdit}
                name="edit-widget"
                customIcon={
                  <IconButton
                    color="brandBlue.500"
                    variant="link"
                    icon={<FaPencilAlt fontSize="12px" />}
                    aria-label="Edit"
                    isDisabled={isDisabled}
                  />
                }
              />
            )}
            <WidgetIconButton
              onClick={removeActionValidation}
              name="remove-widget"
              customIcon={
                <IconButton
                  color="brandBlue.500"
                  variant="link"
                  icon={<BiSolidTrash fontSize="14px" />}
                  aria-label="Remove"
                  isLoading={isFetchingCount || isDeleting}
                  isDisabled={isDisabled}
                />
              }
            />
          </HStack>
        </Flex>
        {children}
      </Flex>
      <ConfirmationModal
        isOpen={isOpen}
        cancelButtonText={"Cancel"}
        onCancel={handleCancel}
        confirmButtonText={"Delete"}
        submitHandler={handleConfirm}
        title={`Delete '${title}'`}
      >
        <Text>
          {!!count && (
            <Text as="span">
              You have {count} contacts currently in this flow step. Deleting
              the flow step might cause contacts to not complete the journey
              workflow.{" "}
            </Text>
          )}
          Are you sure you want to delete?
        </Text>
      </ConfirmationModal>
    </WrapperForCandidate>
  );
}
