import { useSelector } from "react-redux";
import { selectFeatureFlag } from "../../../../common/slices/featureFlagSlice";
import { CONTACTS_INGESTION_SERVICE } from "../../../../common/types/featureFlag";
import SalesforceLegacySyncTab from "./SalesforceLegacySyncTab";
import PersonSalesforceFlinkSyncTab from "./PersonSalesforceFlinkSyncTab";
import { isLoading } from "../../../../common/helper/commonHelper";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import ContentContainer from "../../../../components/v2/ContentContainer";

export default function PersonSalesforceSyncTab({
  internalPersonEmail,
}: {
  internalPersonEmail: string;
}) {
  const {
    contactIngestionEtl: { data: etl, loading },
  } = useSelector(selectFeatureFlag);

  function getSalesforcePersonComponent() {
    switch (etl) {
      case CONTACTS_INGESTION_SERVICE.FLINK:
        return (
          <PersonSalesforceFlinkSyncTab
            internalPersonEmail={internalPersonEmail}
          />
        );
      case CONTACTS_INGESTION_SERVICE.LEGACY:
      default:
        return <SalesforceLegacySyncTab email={internalPersonEmail} />;
    }
  }

  return (
    <ContentContainer flexDir="column">
      <SpinnerContainer loading={isLoading(loading)} h="90vh">
        {getSalesforcePersonComponent()}
      </SpinnerContainer>
    </ContentContainer>
  );
}
