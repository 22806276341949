import {
  Box,
  Flex,
  VStack,
  Button,
  Text,
  Link,
  HStack,
  Progress,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import {
  SyncSettings,
  SalesforceConnection,
} from "../../../../../common/types/salesforceLegacyService";
import SubHeader from "../../components/SubHeader";
import {
  getSalesforceApiUsageCount,
  selectSalesforceLegacy,
  updateSalesforceSettings,
} from "../salesforceLegacySlice";
import { ApiAllocationForm } from "./AllocationForm";
import { useAppDispatch } from "../../../../../store";
import { useSelector } from "react-redux";
import {
  convertToString,
  isLoading,
  percentageOf,
} from "../../../../../common/helper/commonHelper";
import { LOADING_STATES } from "../../../../../common/constants/common";
import SpinnerContainer from "../../../../../components/SpinnerContainer";

const SALESFORCE_LEARN_URL =
  "https://developer.salesforce.com/docs/atlas.en-us.salesforce_app_limits_cheatsheet.meta/salesforce_app_limits_cheatsheet/salesforce_app_limits_platform_api.htm";

function ApiCallsUsed({
  usageCount,
  usageCountLoading,
  maxAllocated,
}: {
  usageCount: number | null;
  usageCountLoading: LOADING_STATES;
  maxAllocated: number | undefined;
}) {
  const percentValue = useMemo(
    () => percentageOf(usageCount ?? 0, maxAllocated ?? 0) as number,
    [usageCount, maxAllocated]
  );
  return (
    <SpinnerContainer loading={isLoading(usageCountLoading)} width="400px">
      <VStack
        px={5}
        py="3"
        spacing="3"
        width="30%"
        h="100px"
        alignItems="flex-start"
      >
        <Text fontSize="sm">API calls used</Text>
        <Box width="100%">
          <Flex justifyContent="flex-end">
            <Text color="gray.500" fontSize="xs">
              {convertToString(usageCount ?? 0)} of{" "}
              {convertToString(maxAllocated ?? 0)}
            </Text>
          </Flex>
          <Progress rounded="md" value={percentValue} />
        </Box>
      </VStack>
    </SpinnerContainer>
  );
}

function SyncFrequencyNote({
  frequency,
  unit,
}: {
  frequency?: string;
  unit?: string;
}) {
  return (
    <Box color="gray.500" px="5" pb="4" fontSize="sm" width="100%">
      {frequency && unit && (
        <Flex>
          <Text>
            Note: Based on your allocation of APIs to Inflection, sync frequency
            will be approximately
            <Box as="span" fontWeight="bold" mx="1">
              {frequency}
            </Box>
            {unit}. Please note that this number is an approximate and could
            change based on several factors
          </Text>
        </Flex>
      )}
    </Box>
  );
}

export default function SalesforceLegacySyncHealthDetails({
  connectionDetails,
  refreshAction,
  saving,
}: {
  connectionDetails: SalesforceConnection;
  refreshAction: () => void;
  saving: boolean;
}) {
  const dispatch = useAppDispatch();
  const { apiUsageCount } = useSelector(selectSalesforceLegacy);

  // TODO fix init 0 error, add comma separators
  const [allocated, setAllocated] = useState<number | undefined>(
    connectionDetails?.sync_settings?.max_allocated || undefined
  );

  function saveSyncSetting() {
    let data: SyncSettings = { max_allocated: allocated };
    dispatch(updateSalesforceSettings(data));
  }

  useEffect(() => {
    if (connectionDetails.sync_settings) {
      setAllocated(connectionDetails?.sync_settings.max_allocated);
    }
    if (connectionDetails.connection_id) {
      dispatch(getSalesforceApiUsageCount(connectionDetails.connection_id));
    }
  }, [
    connectionDetails.sync_settings,
    connectionDetails.connection_id,
    dispatch,
  ]);

  return (
    <Box p="5" bg="white" rounded="md">
      <SubHeader refreshAction={refreshAction} title="Sync details" />
      <Box border="1px" borderColor="gray.100" rounded="lg" mt="2">
        <Flex
          justifyContent="space-between"
          px={5}
          py={3}
          borderBottomWidth="1px"
          borderColor="gray.100"
          bg="grayV2.100"
          alignItems="center"
        >
          <VStack alignItems="left" spacing="1">
            <Text color="gray.600" fontWeight="medium" fontSize="sm">
              API call usage (past 24 hours)
            </Text>
            <Link
              color="blue.500"
              fontWeight="medium"
              fontSize="10px"
              href={SALESFORCE_LEARN_URL}
              target="_blank"
            >
              Learn more about API call limits in Salesforce
            </Link>
          </VStack>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={saveSyncSetting}
            isLoading={saving}
          >
            Save
          </Button>
        </Flex>

        <VStack alignItems="flex-start" bg="white">
          <HStack width="100%" justifyContent="space-between">
            <ApiAllocationForm
              connectionDetails={connectionDetails}
              allocated={allocated}
              setAllocated={setAllocated}
            />

            <ApiCallsUsed
              usageCount={apiUsageCount.data}
              usageCountLoading={apiUsageCount.loading}
              maxAllocated={
                connectionDetails?.sync_settings?.max_allocated || 0
              }
            />
          </HStack>
          <SyncFrequencyNote
            frequency={connectionDetails?.sync_settings?.frequency}
            unit={connectionDetails?.sync_settings?.unit}
          />
        </VStack>
      </Box>
    </Box>
  );
}
